// @flow

import React from 'react'
import { connect } from 'react-redux'
import bindAll from 'lodash/bindAll'
import { fromJS, List, Map } from 'immutable'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import PaginationSupport from '../partials/PaginationSupport'
import moment from 'moment'


import {
	companiesReactivate,
	companiesUpgradeLevel,
	companiesRejectPlanRequest,
	companiesUpdateAccountManager,
} from '../../actions/companies'

import {
	globalAddSweetAlertData,
	globalHideSweetAlert,
} from '../../actions/global'

import TableLoader from '../partials/TableLoader'

import {
	customPlansFetchData,
	customPlansAddFilterPageNumber,
	customPlansAddFilterSearch,
	customPlansAddFilterSort,
	customPlansAddFilterName,
	customPlansAddFilterColPerPage,
	customPlansAddFilterStatus,
	customPlansAddFilterChannel,
	customPlansClearFilterData,
	handleUpdateStatus
} from '../../actions/custom_plans'

require('es6-promise').polyfill()

type Props = {
	customPlansFetchData: Function,
	customPlansPagination: Map<string, any>,
	customPlans: List<Map<string, any>>,
	customPlansFilterData: Map<string, any>,
	customPlansAddFilterPageNumber: Function,
	globalIndicatorData: Map<string, any>,
	customPlansAddFilterSearch: Function,
	customPlansAddFilterSort: Function,
	customPlansAddFilterName: Function,
	customPlansAddFilterColPerPage: Function,
	customPlansAddFilterStatus: Function,
	customPlansClearFilterData: Function,
	companiesReactivate: Function,
	companiesUpgradeLevel: Function,
	companiesRejectPlanRequest: Function,
	companiesUpdateAccountManager: Function,
	handleUpdateStatus: Function,
	customPlansAddFilterChannel: Function,
};

type State = {
	isShown: boolean
};

const statusList =[
	{value:1,label:'Not Yet Met'},
	{value:2,label:'Already Met'},
	{value:3,label:'CRM Updated, Done'}
]

const channelList =[
	{value:'talk to us',label:'Talk to Us'},
	{value:'partnership',label:'Partnership'},
	{value:'training',label:'Training'},
	{value:'demo',label:'Request Demo'},
	{value:'popskul',label:'Popskul'}
]

const userRoles=['Sales Team', 'Account Manager', 'Super Admin']
class CustomPlan extends React.Component<Props, State> {
	state: State;

	constructor(props) {
		super(props)

		this.state = {
			isShown: false,
		}

		bindAll(this, [
			'handleSortChange',
			'handleSearchByName',
			'handleChangePageSize',
			'handleFilterByStatus',
			'creditFormatter',
			'channelFormatter',
			'handleSelectRow',
			'actionFormatter',
			'handleUpgradePlan',
			'administratorFormatter',
			'accountManagerFormatter',
			'handleUpdateAccountManager',
			'appointmentFormatter',
			'showAppointment',
			'statusFormatter',
			'handleUpdateStatusChange',
			'handleFilterByChannel',
			'handleCreateUser'
		])
	}

	componentDidMount() {
		const {
			customPlansFetchData,
			customPlansClearFilterData,
			customPlansAddFilterChannel,
			pathName
		} = this.props

		let channel = ''
		if(pathName === '/support/popskul-request'){
			channel = 'popskul'
		}

		let prevState = this.props.location.state
		if(prevState != undefined && prevState.prevPage === 'custom-plan'){
			customPlansFetchData(this.props.customPlansFilterData)
		}else if(prevState != undefined && prevState.prevPage === 'popskul-request'){
			customPlansAddFilterChannel(channel)
			.then(()=>{
				customPlansFetchData(this.props.customPlansFilterData)
			})
		}else{
			customPlansClearFilterData()
			.then(()=>{
				customPlansAddFilterChannel(channel)
				.then(()=>{
					customPlansFetchData(this.props.customPlansFilterData)
				})
			})
		}
		
		
	}

	componentDidUpdate(prevProps, prevState){
		const {
			customPlansFetchData,
			customPlansClearFilterData,
			customPlansAddFilterChannel,
			pathName
		} = this.props

		let prevPath = prevProps.location.pathname

		if(prevPath!=pathName){
			let channel = ''
			if(pathName === '/support/popskul-request'){
				channel = 'popskul'
			}

			let prevState = this.props.location.state
			if(prevState != undefined && prevState.prevPage === 'custom-plan'){
				customPlansFetchData(this.props.customPlansFilterData)
			}else if(prevState != undefined && prevState.prevPage === 'popskul-request'){
				customPlansAddFilterChannel(channel)
				.then(()=>{
					customPlansFetchData(this.props.customPlansFilterData)
				})
			}else{
				customPlansClearFilterData()
				.then(()=>{
					customPlansAddFilterChannel(channel)
					.then(()=>{
						customPlansFetchData(this.props.customPlansFilterData)
					})
				})
			}

		}

	}

	handleChangePageSize(e): void{
		const{
			customPlansAddFilterPageNumber,
			customPlansFetchData,
			customPlansAddFilterColPerPage,
		}=this.props

		const countFilter = e.target.value
		customPlansAddFilterPageNumber(1)
		.then(()=>{
			customPlansAddFilterColPerPage(countFilter)
			.then(()=>{
				customPlansFetchData(this.props.customPlansFilterData)
			})
		})

	}

	handleSortChange(name, order){
		const {
			customPlansFetchData,
			customPlansAddFilterSort
		} = this.props

		let sort={
			name: name,
			value: order
		}

		customPlansAddFilterSort(sort).then(()=>customPlansFetchData(this.props.customPlansFilterData))

	}

	handleSearchByName(event){
		const{
			customPlansAddFilterPageNumber,
			customPlansFetchData,
			customPlansAddFilterSearch
		}=this.props

		let keywords=event.target.value
		if (event.charCode === 13) {
			customPlansAddFilterPageNumber(1).then(()=>{
				customPlansAddFilterSearch(keywords).then(()=>{
					customPlansFetchData(this.props.customPlansFilterData)
				})
			})
    	}
	}

	handleFilterByStatus(event){
		const{
			customPlansAddFilterPageNumber,
			customPlansFetchData,
			customPlansAddFilterStatus
		}=this.props

		let status=event.target.value
		customPlansAddFilterPageNumber(1).then(()=>{
			customPlansAddFilterStatus(status).then(()=>{
				customPlansFetchData(this.props.customPlansFilterData)
			})
		})
	}

	handleFilterByChannel(event){
		const{
			customPlansAddFilterPageNumber,
			customPlansFetchData,
			customPlansAddFilterChannel
		}=this.props

		let channel=event.target.value
		customPlansAddFilterPageNumber(1).then(()=>{
			customPlansAddFilterChannel(channel).then(()=>{
				customPlansFetchData(this.props.customPlansFilterData)
			})
		})
	}

	creditFormatter(cell: Object, row: Object): Object {
		let colorText='text-red'
		let creditText = `${row.credit_left}`
		if(row.credit_left > 10){
			colorText='text-green'
			creditText = row.credit_left;
		}
		return <span className={colorText}>{creditText}</span>
	}

	channelFormatter(cell: Object, row: Object): Object {
		let colorChannel='tag-blue'
		let tagText = 'Talk to Us';
		if(row.channel == 'talk to us'){
			colorChannel='tag-blue'
			tagText = 'Talk to Us';
		}else if(row.channel == 'partnership'){
			colorChannel='tag-purple'
			tagText = 'Partnership';
		} else if(row.channel == 'training'){
			colorChannel='tag-yellow'
			tagText = 'Training';
		}else if(row.channel == 'popskul'){
			colorChannel='tag-pink'
			tagText = 'POPSkul';
		} else {
			colorChannel='tag-green'
			tagText = 'Request Demo';
		}
		return <div className={`tag-channel ${colorChannel}`}>{tagText}</div>
	}
	
	handleSelectRow(row, cell){
		if(cell < 9 && row.id > 0){
			let upgradeTo = row.plan
			this.props.history.push({ 
				pathname: `/support/companies/${row.id}`,
				state: {prevPage: 'custom-plan', upgradeTo:upgradeTo},
			})
		}
	}

	actionFormatter(cell: Object, row: Object): Object {
		if(row.channel=='popskul'){
			if(row.status!=3){
				return <div className="dropdown">
						<a href="" style={{cursor:'pointer'}} data-toggle="dropdown"><i className="fa fa-ellipsis-h" style={{color: 'black',fontSize: '14px'}}></i></a> 
						<ul className="dropdown-menu pull-right">
							<li><a href="" onClick={(e)=>this.handleCreateUser(e,row)} style={{color: '#404040',padding: '10px 15px'}}>Create an Account</a></li>
						</ul>
					</div>
			}
			
		}

		if(row.company_level===1 || !userRoles.includes(this.props.userRole.get('name'))){
			return ''
		}

		if(row.id < 1){
			return ''
		}

	  	return <div className="dropdown">
					<a href="" style={{cursor:'pointer'}} data-toggle="dropdown"><i className="fa fa-ellipsis-h" style={{color: 'black',fontSize: '14px'}}></i></a> 
					<ul className="dropdown-menu pull-right">
						<li><a href="" onClick={(e)=>this.handleUpgradePlan(e,row.id, 1, row.plan)} style={{color: '#404040',padding: '10px 15px'}}>Upgrade To Enterprise</a></li>
						<li><a href="" onClick={(e)=>this.handleUpgradePlan(e,row.id, 1, row.plan)} style={{color: '#404040',padding: '10px 15px'}}>Create an Account</a></li>
					</ul>
	  			</div>
	}

	handleUpgradePlan(e, companyId, companyLevel, accountType){
		e.preventDefault()
		const {
			globalAddSweetAlertData,
			globalHideSweetAlert,
			companiesUpgradeLevel,
			customPlansFetchData
		} = this.props

		globalAddSweetAlertData({
			show: true,
			showCancelButton: true,
			customClass: 'swal-support',
			confirmButtonColor: '#564FDB',
			confirmButtonText: 'Upgrade',
			cancelButtonText: 'Cancel',
			title: `Upgrade to Enterprise User?`,
			html: true,
			text: `Remaining credits and expiry date will transfer over`,
			onConfirm: () => {
				globalHideSweetAlert()
				companiesUpgradeLevel(companyId, companyLevel, 'custom-plan')
			},
			onCancel: () => {
				globalHideSweetAlert()
			}
		})
	}
	
	administratorFormatter(cell: Object, row: Object): Object {
		return row.administrator+' -<br/>'+row.email
  	}

	accountManagerFormatter(cell: Object, row: Object): Object {
		const {user} = this.props
		let disableChange = true
		if(['nigel@astrnt.co','irene@astrnt.co'].includes(user.get('email'))){
			disableChange = false
		}
		
		return 	<div>
				<select
					className="select-status-payment"
					name="account_manager"
					id="account_manager"
					value={row.account_manager}
					disabled={disableChange}
					onChange={(e)=>this.handleUpdateAccountManager(e, row)}>
						{
							this.props.accountManagers.map((account, index)=>
								<option key={index} value={account.get('id')} selected={row.account_manager_id === account.get('id') ? true:false}>{account.get('name')}</option>
							)
						}
					
				</select>
			</div>
	}

	handleUpdateAccountManager(e, row){
		const{
			companiesUpdateAccountManager
		} = this.props
		companiesUpdateAccountManager(row.id, e.target.value, row.appointment_id, row.channel)
	}

	appointmentFormatter(cell: Object, row: Object): Object{
		if(!row.schedule.id){
			return 	<div>
				<span>-</span><br/>
				<span><a onClick={(e)=>this.showAppointment(e, row)}>see details</a></span>
			</div>
		}else{
			if(row.channel=='training' || row.channel=='partnership'){
				return 	<div>
					<span>-</span><br/>
					<span><a onClick={(e)=>this.showAppointment(e, row)}>see details</a></span>
				</div>
			}else{
				return 	<div>
					<span>{`${moment(row.schedule.date).format('DD/MM/YYYY')}  ${row.schedule.time.substring(0,5)} `}</span><br/>
					<span><a onClick={(e)=>this.showAppointment(e, row)}>see details</a></span>
				</div>
			}
		}
		
	}

	showAppointment(e,company){
		e.preventDefault()
		const {
			globalAddSweetAlertData,
			globalHideSweetAlert
		} = this.props

		const appointment = company.schedule
		let appointmentElement = ''

		if(company.channel === 'partnership'){
			appointmentElement = `<div>
				<table>
					<tr>
						<td width="40%" height="35px">Company/<br/>Organization</td>
						<td><b>${company.title}</b></td>
					</tr>
					<tr>
						<td width="40%" height="35px">Email</td>
						<td><b>${appointment.email_address}</b></td>
					</tr>
					<tr>
						<td width="40%" height="35px">Name</td>
						<td><b>${company.administrator}</b></td>
					</tr>
					<tr>
						<td  width="40%" height="35px">Phone Number</td>
						<td><b>${appointment.phone}</b></td>
					</tr>
					<tr>
						<td  width="40%" height="35px">Project Background</td>
						<td><b>${appointment.prev_achieve_goal}</b></td>
					</tr>
				</table>
			</div>`
		}else if(company.channel === 'demo'){
			appointmentElement = `<div>
				<table>
					<tr>
						<td width="40%" height="35px">Company/<br/>Organization</td>
						<td><b>${company.title}</b></td>
					</tr>
					<tr>
						<td width="40%" height="35px">Email</td>
						<td><b>${appointment.email}</b></td>
					</tr>
					<tr>
						<td  width="40%" height="35px">Name</td>
						<td><b>${appointment.fullname}</b></td>
					</tr>
					<tr>
						<td  width="40%" height="35px">Phone Number</td>
						<td><b>${appointment.phone}</b></td>
					</tr>
					<tr>
						<td  width="40%" height="35px">Preferred Date</td>
						<td><b>${appointment.date!=''? moment(appointment.date).format('DD/MM/YYYY'):''}</b></td>
					</tr>
					<tr>
						<td  width="40%" height="35px">Preferred Time</td>
						<td><b>${appointment.time.substring(0,5) } ${appointment.timezone_code}</b></td>
					</tr>
					<tr>
						<td  width="40%" height="35px">How do you hope Astronaut can help achieve your goals?</td>
						<td><b>${appointment.desc}</b></td>
					</tr>
				</table>
			</div>`
		} else if(company.channel === 'training'){
			appointmentElement = `<div>
				<table>
					<tr>
						<td width="40%" height="35px">Company/<br/>Organization</td>
						<td><b>${company.title}</b></td>
					</tr>
					<tr>
						<td width="40%" height="35px">Email</td>
						<td><b>${appointment.email}</b></td>
					</tr>
					<tr>
						<td  width="40%" height="35px">Name</td>
						<td><b>${appointment.fullname}</b></td>
					</tr>
					<tr>
						<td  width="40%" height="35px">Phone Number</td>
						<td><b>${appointment.phone}</b></td>
					</tr>
					<tr>
						<td  width="40%" height="35px">Topic Interest</td>
						<td><b>${appointment.topic_of_interest}</b></td>
					</tr>
					<tr>
						<td  width="40%" height="35px">Why your team need to upgrade skills</td>
						<td><b>${appointment.desc}</b></td>
					</tr>
					<tr>
						<td  width="40%" height="35px">Preferred method of delivery</td>
						<td><b>${appointment.delivery_method}</b></td>
					</tr>
				</table>
			</div>`
		} else if(company.channel === 'popskul'){
			appointmentElement = `<div>
				<table>
					<tr>
						<td width="40%" height="35px">Company/<br/>Organization</td>
						<td><b>${company.title}</b></td>
					</tr>
					<tr>
						<td width="40%" height="35px">Email</td>
						<td><b>${appointment.email}</b></td>
					</tr>
					<tr>
						<td  width="40%" height="35px">Full Name</td>
						<td><b>${appointment.fullname}</b></td>
					</tr>
					<tr>
						<td  width="40%" height="35px">Phone Number</td>
						<td><b>${appointment.phone}</b></td>
					</tr>
					<tr>
						<td  width="40%" height="35px">Preferred Date</td>
						<td><b>${appointment.date!=''? moment(appointment.date).format('DD/MM/YYYY'):''}</b></td>
					</tr>
					<tr>
						<td  width="40%" height="35px">Preferred Time</td>
						<td><b>${appointment.time.substring(0,5) } ${appointment.timezone_code}</b></td>
					</tr>
					<tr>
						<td  width="40%" height="35px">Job Description</td>
						<td><b>${appointment.desc}</b></td>
					</tr>
				</table>
			</div>`
		}else{
			appointmentElement = `<div>
				<table>
					<tr>
						<td width="40%" height="35px">Company/<br/>Organization</td>
						<td><b>${company.title}</b></td>
					</tr>
					<tr>
						<td width="40%" height="35px">Email</td>
						<td><b>${appointment.email}</b></td>
					</tr>
					<tr>
						<td width="40%" height="35px">Name</td>
						<td><b>${company.administrator}</b></td>
					</tr>
					<tr>
						<td  width="40%" height="35px">Phone Number</td>
						<td><b>${appointment.phone}</b></td>
					</tr>
					<tr>
						<td  width="40%" height="35px">Preferred Date</td>
						<td><b>${appointment.date!=''? moment(appointment.date).format('DD/MM/YYYY'):''}</b></td>
					</tr>
					<tr>
						<td  width="40%" height="35px">Preferred Time</td>
						<td><b>${appointment.time.substring(0,5)} ${appointment.timezone_code}</b></td>
					</tr>
					<tr>
						<td  width="40%" height="35px">Choosen Plan</td>
						<td><b>${company.plan}</b></td>
					</tr>
					<tr>
						<td  width="40%" height="35px">Previous Goal & Objective</td>
						<td><b>${appointment.prev_achieve_goal==null || !appointment.prev_achieve_goal ? '-' : appointment.prev_achieve_goal }</b></td>
					</tr>
					<tr>
						<td  width="40%" height="35px">New Goal & Objective</td>
						<td><b>${appointment.achieve_goal === null || !appointment.achieve_goal ? '-': appointment.achieve_goal}</b></td>
					</tr>
				</table>
			</div>`
		}

		let colorChannel='tag-blue'
		let tagText = 'Talk to Us';
		if(company.channel == 'talk to us'){
			colorChannel='tag-blue'
			tagText = 'Talk to Us';
		}else if(company.channel == 'partnership'){
			colorChannel='tag-purple'
			tagText = 'Partnership';
		} else if(company.channel == 'training'){
			colorChannel='tag-yellow'
			tagText = 'Training';
		} else if(company.channel == 'popskul'){
			colorChannel='tag-pink'
			tagText = 'POPSkul';
		} else {
			colorChannel='tag-green'
			tagText = 'Request Demo';
		}

		const title =  `<div class='tag-channel pull-right ${colorChannel}'>${tagText}</div>`
		
		globalAddSweetAlertData({
			show: true,
			customClass: 'swal-support',
			confirmButtonColor: '#564FDB',
			confirmButtonText: 'Close',
			title: `Schedule - ${company.title} ${title}`,
			html: true,
			text: appointmentElement,
			onConfirm: () => {
				globalHideSweetAlert()
			}
		})

	}

	statusFormatter(cell: Object, row: Object): Object {
		const {
			user
		} = this.props

		let disableChangeStatus = true

		if(row.account_manager_id == user.get('id')){
			disableChangeStatus = false
		}
		
		// if(row.appointment_id==null || !userRoles.includes(this.props.userRole.get('name'))){
		// 	disableChangeStatus = false
		// }

		return 	<div>
				<select
					className="select-status-payment"
					name="status"
					id="status"
					value={row.account_manager}
					disabled={disableChangeStatus}
					onChange={(e)=>this.handleUpdateStatusChange(e, row)}>
						{
							statusList.map((status, index)=>
								<option key={index} value={status.value} selected={row.status === status.value ? true:false}>{status.label}</option>
							)
						}
					
				</select>
			</div>
	}

	handleUpdateStatusChange(e, row){
		const{
			handleUpdateStatus,
			customPlansFilterData,
			customPlansFetchData
		} = this.props
		handleUpdateStatus(row.id, e.target.value, row.appointment_id, row.channel).then(()=>customPlansFetchData(customPlansFilterData))
	}

	handleCreateUser(e, row){
		this.props.history.push({ 
			pathname: `/support/popskul-request/${row.appointment_id}/create-user`,
			state: {prevPage: 'custom-plan'},
		})

	}

  	render() {
		const {
			customPlans,
			customPlansPagination,
			customPlansAddFilterPageNumber,
			customPlansFilterData,
			customPlansFetchData,
			globalIndicatorData,
			user,
			pathName
		} = this.props
		
		let reportPagination: ?Object

		if (!customPlans.isEmpty()) {
			reportPagination =
				<PaginationSupport
					pagination={customPlansPagination}
					fetchData={customPlansFetchData}
					filterData={customPlansFilterData}
					addPageNumber={customPlansAddFilterPageNumber} />
		}

		const isIndicatorClass = globalIndicatorData.get('isShow')? 'p-t-56' : ''
		const options = {
			onSortChange: this.handleSortChange,
			onRowClick: (row, cell)=>this.handleSelectRow(row, cell),
			noDataText: <TableLoader isFetched={this.props.isFetched} feature='custom-plan' />
		};

		let channel = 'Accounts'
		if(pathName === '/support/popskul-request'){
			channel = 'Popskul'
		}

    	return (

			<div className={isIndicatorClass}>
	      		<div className="div-support-container">
	        		<section className="wrapper">
	          			<div className="main-content">
	            			<div className="container-fluid">
	              				<div className="big-panel">
									<div className="flex-container">
										<h5 className="title-heading">{channel} Requiring Actions</h5>
									</div>

									<div className="flex-container flex-between">
										<div>
											Show
											<select
												className="select-status-user"
												name="display"
												id="display"
												defaultValue={customPlansFilterData.get('per_page')}
												onChange={(e)=>this.handleChangePageSize(e)}>
												<option value="10" >10</option>
												<option value="20" >20</option>
												<option value="30" >30</option>
										  	</select>
										 	 Entries
											<select
												className="select-status-user"
												name="status"
												id="status"
												placeholder='Filter by Status'
												defaultValue={customPlansFilterData.get('status')}
												value={customPlansFilterData.get('status')}
												onChange={(e)=>this.handleFilterByStatus(e)}>
													<option value="" disabled selected>Filter by status</option>
													<option value="" >All</option>
													{
														statusList.map((status, index)=>
															<option key={index} value={status.value} selected={customPlansFilterData.get('status') === status.value ? true:false}>{status.label}</option>
														)
													}
												
											</select>
											{
												pathName === '/support/custom-plan' &&
												<select
													className="select-status-user"
													name="channel"
													id="channel"
													placeholder='Filter by Channel'
													defaultValue={customPlansFilterData.get('channel')}
													value={customPlansFilterData.get('channel')}
													onChange={(e)=>this.handleFilterByChannel(e)}>
														<option value="" disabled selected>Filter by channel</option>
														<option value="" >All</option>
														{
															channelList.map((status, index)=>
																<option key={index} value={status.value} selected={customPlansFilterData.get('channel') === status.value ? true:false}>{status.label}</option>
															)
														}
													
												</select>
											}
											
										</div>
										<div>
											<div className="search-support-container horizontal-item--between">
												<input className="search-support" placeholder="Search by name or account holder" defaultValue={this.props.customPlansFilterData.get('search')} onKeyPress={this.handleSearchByName}/>
												<i className="fa fa-search"></i>
											</div>
										</div>
									</div>

	                				<div className="report-list-container form-custom support-company-list account-action-list">
										<BootstrapTable
											data={customPlans.toJS()}
											striped={true}
											options={options}
											hover
										>
											<TableHeaderColumn width='70' dataField="id" isKey={true} dataSort={true}>ID</TableHeaderColumn>
											<TableHeaderColumn dataField="title" dataSort={true}>Name of Company</TableHeaderColumn>
											<TableHeaderColumn dataFormat={this.administratorFormatter} dataSort={false}>Contact Person</TableHeaderColumn>
											<TableHeaderColumn dataFormat={this.accountManagerFormatter} dataSort={false} dataAlign='center'>Account Manager</TableHeaderColumn>
											{/* <TableHeaderColumn dataField="credit_left"  dataFormat={this.creditFormatter} dataSort={true}>Candidate Credits</TableHeaderColumn> */}
											{/* <TableHeaderColumn dataField="valid_date" dataSort={true}>Expiry Date (GMT+7)</TableHeaderColumn> */}
											<TableHeaderColumn dataField="channel"  dataFormat={this.channelFormatter} dataSort={true}>Channel</TableHeaderColumn>
											<TableHeaderColumn dataField="status" dataSort={false} dataFormat={this.statusFormatter}>Status</TableHeaderColumn>
											{/* <TableHeaderColumn dataField="plan" dataSort={true}>Account Type</TableHeaderColumn> */}
											<TableHeaderColumn dataField="appointment" dataSort={false} dataFormat={this.appointmentFormatter}>Schedule Detail</TableHeaderColumn>
											<TableHeaderColumn width='50' dataSort={false} dataFormat={this.actionFormatter} dataAlign='center' columnClassName="dropdown-user-support"></TableHeaderColumn>
										</BootstrapTable>
										{reportPagination}
	                				</div>
	              				</div>
	            			</div>
	          			</div>
	        		</section>
	      		</div>
			</div>
    	)
  	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		customPlans: state.customPlans,
		customPlansPagination: state.customPlansPagination,
		customPlansFilterData: state.customPlansFilterData,
		customPlansIsLoading: state.customPlansIsLoading,
		globalIndicatorData: state.globalIndicatorData,
		isFetched: state.isFetched,
		userPrivilege: state.userPrivilege,
		accountManagers: state.accountManagers,
		user: state.userAuth,
		userRole: state.userAuth.get('role') || fromJS({}),
		pathName: ownProps.location.pathname
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		customPlansFetchData: (filter_data) => dispatch(customPlansFetchData(filter_data)),
		customPlansAddFilterPageNumber: (page) => dispatch(customPlansAddFilterPageNumber(page)),
		customPlansAddFilterSearch: (search) => dispatch(customPlansAddFilterSearch(search)),
		customPlansAddFilterSort: (sort) => dispatch(customPlansAddFilterSort(sort)),
		customPlansAddFilterName: (name) => dispatch(customPlansAddFilterName(name)),
		customPlansAddFilterColPerPage: (col) => dispatch(customPlansAddFilterColPerPage(col)),
		customPlansAddFilterStatus: (status) => dispatch(customPlansAddFilterStatus(status)),
		customPlansClearFilterData: (status) => dispatch(customPlansClearFilterData(status)),
		globalAddSweetAlertData: (options) => dispatch(globalAddSweetAlertData(options)),
		globalHideSweetAlert: () => dispatch(globalHideSweetAlert()),
		companiesReactivate: (filter_data) => dispatch(companiesReactivate(filter_data)),
		companiesUpgradeLevel: (companyId, levelId, prevPage) => dispatch(companiesUpgradeLevel(companyId, levelId, prevPage)),
		companiesRejectPlanRequest: (companyId, isReject) => dispatch(companiesRejectPlanRequest(companyId, isReject)),
		companiesUpdateAccountManager: (companyId, accountManagerId, appointmentId, channel) => dispatch(companiesUpdateAccountManager(companyId, accountManagerId, appointmentId, channel)),
		handleUpdateStatus: (companyId, status, appointmentId, channel) => dispatch(handleUpdateStatus(companyId, status, appointmentId, channel)),
		customPlansAddFilterChannel: (channel) => dispatch(customPlansAddFilterChannel(channel)),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomPlan)
