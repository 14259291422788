// @flow

import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter } from  'react-router'
import { Link } from  'react-router-dom'
import bindAll from 'lodash/bindAll'
import { Scrollbars } from 'react-custom-scrollbars'
import { Map, List } from 'immutable'
import imgAstronautLogo from '../../assets/img/astronaut-logo.png'
import imgAvatar from '../../assets/img/avatar-dum-support.png'
import initializers  from '../../dependencies/initializers'
import { information } from '../../reducers/global'
import astrntLogo from '../../assets/img/astronaut-logo-black.png'

require('es6-promise').polyfill()

type Props = {
	history: Object,
	user: Map<string, any>,
	globalActiveMenu: Map<string, any>,
	featureListCompany: Map<string, any>
};

class HeaderSupportDashboard extends React.Component<Props> {
	constructor(props: Object) {
		super(props)

		bindAll(this, [
			'handleLogout',
		])
	}

	renderCompanyLogo() {
		return (
				<Fragment>
					<img src={astrntLogo} alt="ASTRNT Logo" style={{maxWidth:'200px'}} />
				</Fragment>
			)
	}

	handleLogout(){
		localStorage.removeItem('token')
		window.location = `${initializers.api_host}/auth/logout`
	}

	render() {
		const {
			userAuth,
			isAuthorize,
			userPrivilege,
			information
		} = this.props
		
		const path=window.location.pathname.split('/')[2]

		return(
			<header className="navbar-main navbar-fixed-top navbar-support-dashboard">
			  	<nav className="navbar horizontal-item--between">
			    	<div className="navbar-header">
			      		<button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1">
		          			<span className="sr-only">Toggle Navigation</span>
		          			<span className="icon-bar"></span>
		          			<span className="icon-bar"></span>
		          			<span className="icon-bar"></span>
			      		</button>
						{this.renderCompanyLogo()}
					{/* <label>ASTRONAUT</label> */}
				  
			    	</div>
			    	<div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
					{
					  	isAuthorize &&
						<ul className="nav navbar-top-links navbar-right">
							<li><a href={userAuth.get('dashboard_url')} className={`button-goto-dashboard ${userAuth.get('id') ? '':'hidde'}`}>Go to dashboard</a></li>
							<li><a data-toggle="dropdown" className={`dropdown-toggle ${ information.get('plan_request') && information.get('plan_request')>0  ? 'custom-plan-badge-dot':''}`}><span className="username-support">{userAuth.get('role')==null ? '': userAuth.get('role').get('name')}</span><i className="fa fa-angle-down"></i></a>
								<ul className="dropdown-menu dropdown-user logout">
									{
										userPrivilege.includes('SHOW_COMPANY_PAGE') &&
										<li>
											<Link to={`/support/companies`}>Company</Link>
										</li>
									}
									{
										userPrivilege.includes('SHOW_COMPANY_PAGE') &&
										<li>
											<Link to={`/support/university`}>University</Link>
										</li>
									}
									{
										userPrivilege.includes('SHOW_FEATURES_PAGE') &&
										<li>
											<Link to={`/support/features`} >Features</Link>
										</li>
									}
									{
										userPrivilege.includes('SHOW_INTERNAL_USERS_PAGE') &&
										<li>
											<Link to={`/support/internal-user`}>Internal User</Link>
										</li>
									}
									{
										userPrivilege.includes('SHOW_TRANSACTIONS_PAGE') &&
										<li>
											<Link to={`/support/transactions`} >Quota Topup</Link>
										</li>
									}
									{
										userPrivilege.includes('SHOW_USERS_PAGE') &&
										<li>
											<Link to={`/support/users`}>Users</Link>
										</li>
									}
									{
										userPrivilege.includes('SHOW_USER_LEVELS_PAGE') &&
										<li>
											<Link to={`/support/user-level`}>User Level</Link>
										</li>
									}
									{
										userPrivilege.includes('SHOW_COMPANY_PAGE') &&
										<li>
											<Link data-badge ={this.props.totalAccountRequiringAction} className={`${ this.props.totalAccountRequiringAction>0  ? 'custom-plan-badge':''}`} to={`/support/custom-plan`}>Accounts Requiring Actions</Link>
										</li>
									}
									{
										userPrivilege.includes('SHOW_COMPANY_PAGE') &&
										<li>
											<Link data-badge ={information.get('popskul_request')} className={`${ information.get('popskul_request') && information.get('popskul_request')>0  ? 'custom-plan-badge':''}`} to={`/support/popskul-request`}>Popskul Requiring Actions</Link>
										</li>
									}
								</ul>
							</li>
							<li className="dropdown">
								<a data-toggle="dropdown" className="dropdown-toggle">
									<img src={imgAvatar} className="m-r-16" />
								<span className="username-support">{userAuth.get('preferred_name') || userAuth.get('name')}</span>
								</a>
								<ul className="dropdown-menu dropdown-user logout">
									<li className="footer"><a onClick={this.handleLogout}>Log Out</a></li>
								</ul>
							</li>
						</ul>
					}
			    	</div>
			  	</nav>
			</header>
		)
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		userAuth: state.userAuth,
		globalActiveMenu: state.globalActiveMenu,
		sessionsToken: state.sessionsToken,
		isAuthorize: state.isAuthorize,
		userPrivilege: state.userPrivilege,
		information: state.information,
		totalAccountRequiringAction: state.totalAccountRequiringAction
	}
}

const mapDispatchToProps = (dispatch) => {
	return {

	}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HeaderSupportDashboard))
