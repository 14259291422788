// @flow

import React , { Fragment } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import bindAll from 'lodash/bindAll'
import isEmpty from 'lodash/isEmpty'
import { Field, reduxForm, change, touch, stopAsyncValidation } from 'redux-form'
import validate from './validate'
import RenderInput from '../partials/RenderInput'
import RenderTextArea from '../partials/RenderTextArea'
import RenderSelect from '../partials/RenderSelect'
import RenderSelectMulti from '../partials/RenderSelectMulti'
import Upload from 'rc-upload'
import {
	globalAddSweetAlertData,
	globalHideSweetAlert,
} from '../../actions/global'

import {
	companiesGetDetail,
	companiesUpdate,
	companyLocationByCountry
} from '../../actions/companies'

import defaultLogo from '../../assets/img/default-company-image.png'
import defaultBanner from '../../assets/img/banner-default.png'
import { List, Map, fromJS } from 'immutable'

type Props = {
	globalAddSweetAlertData: Function,
	globalHideSweetAlert: Function,
	companiesGetDetail: Function,
	companiesUpdate: Function,
	companiesId: string,
	handleSubmit: Function,
	pristine: boolean,
	submitting: boolean,
	invalid:boolean,
	changeFieldValue: Function,
	touchForm: Function,
	stopAsyncValidationForm: Function,
	companyLocationByCountry: Function,
};

class CompanyEdit extends React.Component<Props, State> {
	state: State;

	constructor(props) {
		super(props)

		this.state = {
			errLogoUrl: true,
			errMsgLogoUrl:``,
			logoPreview: '',
			isOtherIndustry:false,
			errBannerUrl: true,
			errMsgBannerUrl:``,
			bannerPreview: '',
		}

		bindAll(this, [
			'handleSaveData',
			'handleUploadChange',
			'handleIndustryChange',
			'handleUploadBannerChange',
			'renderError',
			'handleCountryChange',
			'handleSegmentTypeChange',
		])
	}

	componentDidMount() {
		const {
			companiesGetDetail,
            companiesId,
			changeFieldValue
		} = this.props
		companiesGetDetail(companiesId).then(()=>{
			this.setState({
				errLogoUrl: isEmpty(this.props.company.get('logoUrl')),
				logoPreview: this.props.company.get('logoUrl'),
				errBannerUrl: isEmpty(this.props.company.get('banner_url')),
				bannerPreview: this.props.company.get('banner_url')
			})
			changeFieldValue('title',this.props.company.get('title'), true, true)
			changeFieldValue('website',this.props.company.get('website'), true, true)
			changeFieldValue('profileDescription',this.props.company.get('profileDescription'), true, true)
			changeFieldValue('country_id',this.props.company.get('country_id'), true, true)
			changeFieldValue('company_size',this.props.company.get('company_size'), true, true)
			changeFieldValue('industry',this.props.company.get('industry'), true, true)
			const industries = ['Agriculture','Banking/Finance','Construction','Creative/Advertising','Education','Fast Moving Consumer Goods (FMCG)','Manufacturing','NGO', 'Pharmaceuticals','Software/IT','Startups','Telecommunications']
			if(!industries.includes(this.props.company.get('industry'))){
				changeFieldValue('industry','Other', true, true)
				changeFieldValue('other_industry',this.props.company.get('industry'), true, true)
				this.setState({isOtherIndustry: true})
			}else{
				changeFieldValue('other_industry','', true, true)
				changeFieldValue('industry',this.props.company.get('industry'), true, true)
			}

			this.props.companyLocationByCountry(this.props.company.get('country_id')).then(()=>{
				if(this.props.company.get('city')){
					changeFieldValue('industry',this.props.company.get('city'), true, true)
				}
			});
		})
	}

	handleSaveData(values){
		const {
			globalAddSweetAlertData,
			globalHideSweetAlert,
			companiesUpdate,
			companiesId
		} = this.props

		values.industry = values.industry==='Other' ? values.other_industry : values.industry
		companiesUpdate(companiesId, values).then(()=>{
			this.props.history.push(`/support/companies/${companiesId}`)
		})
	}

	handleUploadChange(){
		const { changeFieldValue } = this.props
		return {
			multiple: false,
			customRequest: ({
		    action,
		    data,
		    file,
		    filename,
		    headers,
		    onError,
		    onProgress,
		    onSuccess,
		    withCredentials
		  }) => {
				const reader = new FileReader()
				reader.onloadend = () => {
					changeFieldValue('logoUrl', file)
					this.setState({
						errLogoUrl: false,
						logoPreview: reader.result,
						errMsgLogoUrl: ``
					})
				}

				reader.readAsDataURL(file)
			},
			onStart: (file) => {
		    
			}
		}
	}

	handleUploadBannerChange(){
		const { changeFieldValue, touchForm, stopAsyncValidationForm } = this.props
		return {
			multiple: false,
			customRequest: ({
		    action,
		    data,
		    file,
		    filename,
		    headers,
		    onError,
		    onProgress,
		    onSuccess,
		    withCredentials
		  }) => {
				const reader = new FileReader()
				reader.onloadend = () => {
					changeFieldValue('banner_image', file)
					this.setState({
						errBannerUrl: false,
						bannerPreview: reader.result,
						errMsgBannerUrl: ``
					})
				}

				reader.readAsDataURL(file)
			},
			onStart: (file) => {
		    
			},
			beforeUpload(file, fileList) {
			return new Promise((resolve, reject) => {
				console.log('file uploaded', file);
				let allowedFileType = ['image/png','image/jpg','image/jpeg']
				if (allowedFileType.includes(file.type)) {
					if (file.size > 1000000) {
						touchForm(['banner_image'])
						stopAsyncValidationForm({banner_image: 'Max upload size is 1 MB'})
					} else {
								resolve(file)
				}
				} else {
					touchForm(['banner_image'])
					stopAsyncValidationForm({banner_image: `${file.name} is not supported`})
				}
			})
		  }
		}
	}

	handleIndustryChange(e){
		let isOtherIndustry = e.target.value == 'Other' ? true:false
		this.setState({isOtherIndustry: isOtherIndustry})
	}
	handleSegmentTypeChange(e){
	}

	handleCountryChange(e){
		let countryId = e.target.value;
		this.props.companyLocationByCountry(countryId)
	}

	renderError({ meta: { touched, error } }) {
		return touched && error ? <div><span className="error">{error}</span></div> : false
	}

  	render() {
		const {
			company,
			pristine,
			submitting,
			handleSubmit,
			invalid,
			errors,
			timezones,
		} = this.props

		let detailcompany = company.toJS();
		let countryLists = fromJS(company.get('country_list')) || []
		let locationLists = fromJS(company.get('location')) || []
		let industryLists = [
			{ value: "Agriculture", label:"Agriculture"},
			{ value: "Banking/Finance", label:"Banking/Finance"},
			{ value: "Construction", label:"Construction"},
			{ value: "Creative/Advertising", label:"Creative/Advertising"},
			{ value: "Education", label:"Education"},
			{ value: "Fast Moving Consumer Goods (FMCG)", label:"Fast Moving Consumer Goods (FMCG)"},
			{ value: "Manufacturing", label:"Manufacturing"},
			{ value: "NGO", label:"NGO"},
			{ value: "Pharmaceuticals", label:"Pharmaceuticals"},
			{ value: "Software/IT", label:"Software/IT"},
			{ value: "Startups", label:"Startups"},
			{ value: "Telecommunications", label:"Telecommunications"},
			{ value: "Other", label:"Other"},
		]
		let companySizeList=[
			{value: "Less than 10", label: "Less than 10"},
			{value: "10 - 50", label: "10 - 50"},
			{value: "51 - 100", label: "51 - 100"},
			{value: "100+", label: "100+"}
		]

		let segmentTypes = [
			{value: "Talent Acquisition", label: "Talent Acquisition", description:'Hire top talent efficiently. Make data-driven hiring decisions.'},
			{value: "Talent Management", label: "Talent Management", description:'Develop and retain high-performing employees. Build a strong workforce.'},
			{value: "Small Medium Enterprise", label: "Small Medium Enterprise", description:'Hire the right people to grow your business. Optimize hiring processes.'},
			{value: "University Admission", label: "University Admission", description:'Select qualified students for enrollment. Build a diverse student body.'},
			{value: "Career Development Center", label: "Career Development Center", description:'Prepare students for successful careers. Measure student outcomes.'}
		]

		return (
			<div className="div-support-container">
				<section className="wrapper">
					<div className="main-content">
						<div className="container-fluid">
							<div className="big-panel">
								<form id="company-form_edit_organization" onSubmit={handleSubmit(this.handleSaveData)} encType="multipart/form-data">
									<div className="flex-container horizontal-item--between m-t-40">
										<h5 className="title-heading m-b-0" data-toggle="tooltip" data-placement="right" title="Talent acquisition involves finding, attracting, and hiring skilled employees for an organization.">Edit Organization</h5>
										<div>
											<Link to={`/support/companies/${detailcompany.id}`} className="btn-support-action secondary-border m-r-12">Cancel</Link>
											{
												company.get('channel')=='career_development_center' ? 
												<button disabled={ submitting || invalid  || pristine || this.state.errLogoUrl} className="btn-support-action primary m-r-12">Save</button>:
												<button disabled={ submitting || invalid  || pristine || this.state.errLogoUrl || this.state.errBannerUrl} className="btn-support-action primary m-r-12">Save</button>
											}
										</div>
									</div>
									<div className="flex-container flex-between m-t-40 m-b-80">
										<div className="col-md-12">
											<div className="company-edit-card edit-card">
												<div>
													<label className="sub-heading">Details</label>
												</div>
												<div className="logo-desc-container vertical-item--top">
													<div className="logo-container">
														<Upload {...this.handleUploadChange()} className="">
															<div>
																{this.state.logoPreview!='' ?
																	<Fragment>
																		<div className="horizontal-item--center logo-div">
																			<img src={this.state.logoPreview} />
																		</div>
																		{/* <p><a onChange={(e) => {e.preventDefault()}}>Change logo</a></p> */}
																		<div className="horizontal-item--center m-t-16"><button type="button"  className="btn-support-action secondary-border w-100">Upload Company Logo</button></div>
																	</Fragment>
																	:
																	<Fragment>
																		<div className="horizontal-item--center logo-div">
																			<img src={defaultLogo} className="img-responsive" alt=""/>
																		</div>
																		<div className="horizontal-item--center m-t-16"><button type="button"  className="btn-support-action secondary-border w-100">Upload Company Logo</button></div>
																	</Fragment>
																}
															</div>
														</Upload>
														{!isEmpty(errors) ? <span className="error">{errors.logoUrl}</span> : ''}
													</div>
													<div className="desc-container flex-container flex-grow">
														{
															['astrnt','popskul'].includes(company.get('channel')) &&
															<div>
																<label className="sub-heading">Company's Banner</label>
															</div>

														}
														{
															['astrnt','popskul'].includes(company.get('channel')) &&
															<div className='m-b-30'>
																<Upload {...this.handleUploadBannerChange()} className="">
																	<div>
																		{this.state.bannerPreview!='' ?
																			<Fragment>
																				<div className="horizontal-item--center logo-div">
																					<img src={this.state.bannerPreview} className="img-responsive w-100" />
																				</div>
																				{/* <p><a onChange={(e) => {e.preventDefault()}}>Change logo</a></p> */}
																				<div className="horizontal-item--left m-t-16"><button type="button"  className="btn-support-action secondary-border">Upload Company Banner</button></div>
																			</Fragment>
																			:
																			<Fragment>
																				<div className="horizontal-item--center logo-div">
																					<img src={defaultBanner}  alt=""/>
																				</div>
																				<div className="horizontal-item--left m-t-16"><button type="button"  className="btn-support-action secondary-border">Upload Company Banner</button></div>
																			</Fragment>
																		}
																	</div>
																</Upload>
																<Field name="banner_image" component={this.renderError}/>
															</div>
														}
														
														
														<div>
															<Field
																name="title"
																type="text"
																component={RenderInput}
																label={company.get('channel')=='career_development_center' ? 'University Name':'Company Name' }
																placeholder={company.get('channel')=='career_development_center' ? 'University Name':'Company Name' }
																id="title"
															/>
														</div>
														<div>
															<Field
																name="website"
																type="text"
																component={RenderInput}
																label="Website"
																placeholder="Website"
																id="website"
															/>
														</div>
														<div>
															<Field
																name="profileDescription"
																type="textarea"
																component={RenderTextArea}
																label={company.get('channel')=='career_development_center' ? 'About University':'About Company' }
																placeholder={company.get('channel')=='career_development_center' ? 'About University':'About Company' }
																id="profileDescription"
															/>
														</div>
														<div className="form-group">
															<Field
																name="country_id"
																component={RenderSelect}
																label="Country/Region*"
																placeholder="Select Industry"
																id="country_id"
																defaultValue={company.get('country_id')}
																dropdownOption={countryLists}
																searchable={true}
																onChange={this.handleCountryChange}
															/>
														</div>
														{
															(detailcompany.popskul_connection == 1 || company.get('channel')=='career_development_center') && 
															<div className="form-group">
																<Field
																	name="city"
																	component={RenderSelect}
																	label="Location*"
																	placeholder="Select Location"
																	id="city"
																	searchable={true}
																	defaultValue={company.get('city')}
																	dropdownOption={locationLists}
																/>
															</div>
														}
														
														<div className="form-group">
															<Field
																name="timezone_id"
																component={RenderSelect}
																label="Preferred Time Zone*"
																placeholder="Select Preferred Time Zone"
																id="timezone_id"
																defaultValue={company.get('timezone_id')}
																dropdownOption={timezones}
																searchable={true}
															/>
														</div>
														{
															['astrnt','popskul'].includes(company.get('channel')) ? 
															<div className="form-group">
																<Field
																	name="company_size"
																	component={RenderSelect}
																	label="Size of company/organization *"
																	placeholder="Select Size of company/organization"
																	id="company_size"
																	defaultValue={company.get('company_size')}
																	dropdownOption={fromJS(companySizeList)}
																	searchable={true}
																/>
															</div>
															:
															
															<div className="form-group">
																<Field
																	name="company_size"
																	type="text"
																	component={RenderInput}
																	label="Number of Students *"
																	placeholder="Number of Students"
																	id="company_size"
																/>
															</div>
														}

														{
															['astrnt','popskul'].includes(company.get('channel')) &&
															<div className="form-group">
																<Field
																	name="industry"
																	component={RenderSelect}
																	label="Which industry defines your company/organization? *"
																	placeholder="Select Industry"
																	id="industry"
																	defaultValue={company.get('industry')}
																	dropdownOption={fromJS(industryLists)}
																	onChange={this.handleIndustryChange}
																	searchable={true}
																/>
															</div>

														}
														
														{
															(['astrnt','popskul'].includes(company.get('channel')) && this.state.isOtherIndustry) &&
															<div className="form-group">
																<Field
																	name="other_industry"
																	type="text"
																	component={RenderInput}
																	placeholder="e.g: Hospitality"
																	label="Please specify the industry if you choose other"
																	className="form-control" />
															</div>
														}

														<div className="form-group">
															<Field
																name="segment_type"
																component={RenderSelectMulti}
																label="Segment Type *"
																placeholder="Segment Type"
																id="segment_type"
																defaultValue={company.get('segment_type')}
																options={segmentTypes}
																onChange={this.handleSegmentTypeChange}
																searchable={true}
																multiple={true}
															/>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</section>
			</div>
		)
  	}
}

CompanyEdit = reduxForm({
  form: 'CompanyEdit',
  validate: validate,
	initialValues: {
		title: '',
		website:'',
		profileDescription: "",
		country_id: "",
		company_size: "",
		industry: "",
		other_industry: "",
		timezone_id: "",
		channel: "",
		city: "",
		segment_type:""
  },
	enableReinitialize: true
})(CompanyEdit)

// const selector = formValueSelector('CompanyEdit')

const mapStateToProps = (state, ownProps) => {
	const company = state.company
	const industries = ['Agriculture','Banking/Finance','Construction','Creative/Advertising','Education','Fast Moving Consumer Goods (FMCG)','Manufacturing','NGO', 'Pharmaceuticals','Software/IT','Startups','Telecommunications']
	const initialValues = {
		title: company.get('title'),
		website: company.get('website'),
		profileDescription: company.get('profileDescription'),
		country_id: company.get('country_id'),
		company_size: company.get('company_size'),
		industry: industries.includes(company.get('industry')) ?  company.get('industry') : 'Other',
		other_industry: industries.includes(company.get('industry')) ? '' : company.get('industry'),
		timezone_id: company.get('timezone_id'),
		city: company.get('city'),
		popskul_connection: company.get('popskul_connection'),
		channel: company.get('channel'),
		segment_type: company.get('segment_type')
	}
	return {
		initialValues,
		pathName: ownProps.location.pathname,
		globalIndicatorData: state.globalIndicatorData,
		dropdownUserLevels: state.dropdownUserLevels,
		companiesId: ownProps.match.params.companiesId,
		company: state.company,
		timezones: state.timezones
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		globalAddSweetAlertData: (options) => dispatch(globalAddSweetAlertData(options)),
		globalHideSweetAlert: () => dispatch(globalHideSweetAlert()),
		companiesGetDetail: (filter_data) => dispatch(companiesGetDetail(filter_data)),
		companiesUpdate: (companyId, params) => dispatch(companiesUpdate(companyId, params)),
		changeFieldValue: (field, value, touched, pristine) => dispatch(change('CompanyEdit', field, value, touched, pristine)),
		touchForm: (field) => dispatch(touch('CompanyEdit', field)),
		stopAsyncValidationForm: (object) => dispatch(stopAsyncValidation('CompanyEdit', object)),
		companyLocationByCountry: (countryId) => dispatch(companyLocationByCountry(countryId)),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyEdit)
