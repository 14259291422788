// @flow

import React from 'react'
import { connect } from 'react-redux'
import bindAll from 'lodash/bindAll'
import { fromJS, List, Map } from 'immutable'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import PaginationSupport from '../partials/PaginationSupport'
import moment from 'moment'
import ModalPreviewJob from '../partials/ModalPreviewJob'
import $ from 'jquery'


import {
	companiesReactivate,
	companiesUpgradeLevel,
	companiesRejectPlanRequest,
	companiesUpdateAccountManager,
} from '../../actions/companies'

import {
	globalAddSweetAlertData,
	globalHideSweetAlert,
} from '../../actions/global'

import TableLoader from '../partials/TableLoader'

import {
	publishJobsFetchData,
	publishJobsAddFilterPageNumber,
	publishJobsAddFilterSearch,
	publishJobsAddFilterSort,
	publishJobsAddFilterName,
	publishJobsAddFilterColPerPage,
	publishJobsAddFilterStatus,
	publishJobsAddFilterChannel,
	publishJobsClearFilterData,
	handleUpdateStatus
} from '../../actions/job'

require('es6-promise').polyfill()

type Props = {
	publishJobsFetchData: Function,
	publishJobsPagination: Map<string, any>,
	publishJobs: List<Map<string, any>>,
	publishJobsFilterData: Map<string, any>,
	publishJobsAddFilterPageNumber: Function,
	globalIndicatorData: Map<string, any>,
	publishJobsAddFilterSearch: Function,
	publishJobsAddFilterSort: Function,
	publishJobsAddFilterName: Function,
	publishJobsAddFilterColPerPage: Function,
	publishJobsAddFilterStatus: Function,
	publishJobsClearFilterData: Function,
	companiesReactivate: Function,
	companiesUpgradeLevel: Function,
	companiesRejectPlanRequest: Function,
	companiesUpdateAccountManager: Function,
	handleUpdateStatus: Function,
	publishJobsAddFilterChannel: Function,
};

type State = {
	isShown: boolean
};

const statusList =[
	{value:0,label:'Need Review'},
	{value:1,label:'Published'},
	{value:2,label:'Rejected'}
]

const userRoles=['Sales Team', 'Account Manager', 'Super Admin']
class PublishJob extends React.Component<Props, State> {
	state: State;

	constructor(props) {
		super(props)

		this.state = {
			isShown: false,
			selectedJob:{}
		}

		bindAll(this, [
			'handleSortChange',
			'handleSearchByName',
			'handleChangePageSize',
			'handleFilterByStatus',
			'handleSelectRow',
			'actionFormatter',
			'handleShowDetail',
			'statusFormatter',
			'handleUpdateStatusChange',
			'handlePreviewJob',
			'handleStatusUpdate'
		])
	}

	componentDidMount() {
		const {
			publishJobsFetchData,
			publishJobsClearFilterData,
			publishJobsAddFilterChannel,
			pathName
		} = this.props

		let channel = ''
		if(pathName === '/support/popskul-request'){
			channel = 'popskul'
		}

		let prevState = this.props.location.state
		if(prevState != undefined && prevState.prevPage === 'custom-plan'){
			publishJobsFetchData(this.props.publishJobsFilterData)
		}else if(prevState != undefined && prevState.prevPage === 'popskul-request'){
			publishJobsAddFilterChannel(channel)
			.then(()=>{
				publishJobsFetchData(this.props.publishJobsFilterData)
			})
		}else{
			publishJobsClearFilterData()
			.then(()=>{
				publishJobsAddFilterChannel(channel)
				.then(()=>{
					publishJobsFetchData(this.props.publishJobsFilterData)
				})
			})
		}
		
		
	}

	componentDidUpdate(prevProps, prevState){
		const {
			publishJobsFetchData,
			publishJobsClearFilterData,
			publishJobsAddFilterChannel,
			pathName
		} = this.props

		let prevPath = prevProps.location.pathname

		if(prevPath!=pathName){
			let channel = ''
			if(pathName === '/support/popskul-request'){
				channel = 'popskul'
			}

			let prevState = this.props.location.state
			if(prevState != undefined && prevState.prevPage === 'custom-plan'){
				publishJobsFetchData(this.props.publishJobsFilterData)
			}else if(prevState != undefined && prevState.prevPage === 'popskul-request'){
				publishJobsAddFilterChannel(channel)
				.then(()=>{
					publishJobsFetchData(this.props.publishJobsFilterData)
				})
			}else{
				publishJobsClearFilterData()
				.then(()=>{
					publishJobsAddFilterChannel(channel)
					.then(()=>{
						publishJobsFetchData(this.props.publishJobsFilterData)
					})
				})
			}

		}

	}

	handleChangePageSize(e): void{
		const{
			publishJobsAddFilterPageNumber,
			publishJobsFetchData,
			publishJobsAddFilterColPerPage,
		}=this.props

		const countFilter = e.target.value
		publishJobsAddFilterPageNumber(1)
		.then(()=>{
			publishJobsAddFilterColPerPage(countFilter)
			.then(()=>{
				publishJobsFetchData(this.props.publishJobsFilterData)
			})
		})

	}

	handleSortChange(name, order){
		const {
			publishJobsFetchData,
			publishJobsAddFilterSort
		} = this.props

		let sort={
			name: name,
			value: order
		}

		publishJobsAddFilterSort(sort).then(()=>publishJobsFetchData(this.props.publishJobsFilterData))

	}

	handleSearchByName(event){
		const{
			publishJobsAddFilterPageNumber,
			publishJobsFetchData,
			publishJobsAddFilterSearch
		}=this.props

		let keywords=event.target.value
		if (event.charCode === 13) {
			publishJobsAddFilterPageNumber(1).then(()=>{
				publishJobsAddFilterSearch(keywords).then(()=>{
					publishJobsFetchData(this.props.publishJobsFilterData)
				})
			})
    	}
	}

	handleFilterByStatus(event){
		const{
			publishJobsAddFilterPageNumber,
			publishJobsFetchData,
			publishJobsAddFilterStatus
		}=this.props

		let status=event.target.value
		publishJobsAddFilterPageNumber(1).then(()=>{
			publishJobsAddFilterStatus(status).then(()=>{
				publishJobsFetchData(this.props.publishJobsFilterData)
			})
		})
	}

	
	handleSelectRow(row, cell){
		if(cell < 9 && row.id > 0){
			let upgradeTo = row.plan
			this.props.history.push({ 
				pathname: `/support/companies/${row.id}`,
				state: {prevPage: 'custom-plan', upgradeTo:upgradeTo},
			})
		}
	}

	actionFormatter(cell: Object, row: Object): Object {
	  	return 	<div>
		  <span><a onClick={(e)=>this.handlePreviewJob(e, row)}>See Details</a></span>
	  </div>
	}


	handleShowDetail(e,company){
		e.preventDefault()
		const {
			globalAddSweetAlertData,
			globalHideSweetAlert
		} = this.props

		const appointment = company.schedule
		let appointmentElement = ''

		if(company.status === 'partnership'){
			appointmentElement = `<div>
				<table>
					<tr>
						<td width="40%" height="35px">Company/<br/>Organization</td>
						<td><b>${company.title}</b></td>
					</tr>
					<tr>
						<td width="40%" height="35px">Email</td>
						<td><b>${appointment.email_address}</b></td>
					</tr>
					<tr>
						<td width="40%" height="35px">Name</td>
						<td><b>${company.administrator}</b></td>
					</tr>
					<tr>
						<td  width="40%" height="35px">Phone Number</td>
						<td><b>${appointment.phone}</b></td>
					</tr>
					<tr>
						<td  width="40%" height="35px">Project Background</td>
						<td><b>${appointment.prev_achieve_goal}</b></td>
					</tr>
				</table>
			</div>`
		}else if(company.status === 'demo'){
			appointmentElement = `<div>
				<table>
					<tr>
						<td width="40%" height="35px">Company/<br/>Organization</td>
						<td><b>${company.title}</b></td>
					</tr>
					<tr>
						<td width="40%" height="35px">Email</td>
						<td><b>${appointment.email}</b></td>
					</tr>
					<tr>
						<td  width="40%" height="35px">Name</td>
						<td><b>${appointment.fullname}</b></td>
					</tr>
					<tr>
						<td  width="40%" height="35px">Phone Number</td>
						<td><b>${appointment.phone}</b></td>
					</tr>
					<tr>
						<td  width="40%" height="35px">Preferred Date</td>
						<td><b>${appointment.date!=''? moment(appointment.date).format('DD/MM/YYYY'):''}</b></td>
					</tr>
					<tr>
						<td  width="40%" height="35px">Preferred Time</td>
						<td><b>${appointment.time.substring(0,5) } ${appointment.timezone_code}</b></td>
					</tr>
					<tr>
						<td  width="40%" height="35px">How do you hope Astronaut can help achieve your goals?</td>
						<td><b>${appointment.desc}</b></td>
					</tr>
				</table>
			</div>`
		} else if(company.status === 'training'){
			appointmentElement = `<div>
				<table>
					<tr>
						<td width="40%" height="35px">Company/<br/>Organization</td>
						<td><b>${company.title}</b></td>
					</tr>
					<tr>
						<td width="40%" height="35px">Email</td>
						<td><b>${appointment.email}</b></td>
					</tr>
					<tr>
						<td  width="40%" height="35px">Name</td>
						<td><b>${appointment.fullname}</b></td>
					</tr>
					<tr>
						<td  width="40%" height="35px">Phone Number</td>
						<td><b>${appointment.phone}</b></td>
					</tr>
					<tr>
						<td  width="40%" height="35px">Topic Interest</td>
						<td><b>${appointment.topic_of_interest}</b></td>
					</tr>
					<tr>
						<td  width="40%" height="35px">Why your team need to upgrade skills</td>
						<td><b>${appointment.desc}</b></td>
					</tr>
					<tr>
						<td  width="40%" height="35px">Preferred method of delivery</td>
						<td><b>${appointment.delivery_method}</b></td>
					</tr>
				</table>
			</div>`
		} else if(company.status === 'popskul'){
			appointmentElement = `<div>
				<table>
					<tr>
						<td width="40%" height="35px">Company/<br/>Organization</td>
						<td><b>${company.title}</b></td>
					</tr>
					<tr>
						<td width="40%" height="35px">Email</td>
						<td><b>${appointment.email}</b></td>
					</tr>
					<tr>
						<td  width="40%" height="35px">Full Name</td>
						<td><b>${appointment.fullname}</b></td>
					</tr>
					<tr>
						<td  width="40%" height="35px">Phone Number</td>
						<td><b>${appointment.phone}</b></td>
					</tr>
					<tr>
						<td  width="40%" height="35px">Preferred Date</td>
						<td><b>${appointment.date!=''? moment(appointment.date).format('DD/MM/YYYY'):''}</b></td>
					</tr>
					<tr>
						<td  width="40%" height="35px">Preferred Time</td>
						<td><b>${appointment.time.substring(0,5) } ${appointment.timezone_code}</b></td>
					</tr>
					<tr>
						<td  width="40%" height="35px">Job Description</td>
						<td><b>${appointment.desc}</b></td>
					</tr>
				</table>
			</div>`
		}else{
			appointmentElement = `<div>
				<table>
					<tr>
						<td width="40%" height="35px">Company/<br/>Organization</td>
						<td><b>${company.title}</b></td>
					</tr>
					<tr>
						<td width="40%" height="35px">Email</td>
						<td><b>${appointment.email}</b></td>
					</tr>
					<tr>
						<td width="40%" height="35px">Name</td>
						<td><b>${company.administrator}</b></td>
					</tr>
					<tr>
						<td  width="40%" height="35px">Phone Number</td>
						<td><b>${appointment.phone}</b></td>
					</tr>
					<tr>
						<td  width="40%" height="35px">Preferred Date</td>
						<td><b>${appointment.date!=''? moment(appointment.date).format('DD/MM/YYYY'):''}</b></td>
					</tr>
					<tr>
						<td  width="40%" height="35px">Preferred Time</td>
						<td><b>${appointment.time.substring(0,5)} ${appointment.timezone_code}</b></td>
					</tr>
					<tr>
						<td  width="40%" height="35px">Choosen Plan</td>
						<td><b>${company.plan}</b></td>
					</tr>
					<tr>
						<td  width="40%" height="35px">Previous Goal & Objective</td>
						<td><b>${appointment.prev_achieve_goal==null || !appointment.prev_achieve_goal ? '-' : appointment.prev_achieve_goal }</b></td>
					</tr>
					<tr>
						<td  width="40%" height="35px">New Goal & Objective</td>
						<td><b>${appointment.achieve_goal === null || !appointment.achieve_goal ? '-': appointment.achieve_goal}</b></td>
					</tr>
				</table>
			</div>`
		}

		let colorChannel='tag-blue'
		let tagText = 'Talk to Us';
		

		const title =  `<div class='tag-channel pull-right ${colorChannel}'>${tagText}</div>`
		
		globalAddSweetAlertData({
			show: true,
			customClass: 'swal-support',
			confirmButtonColor: '#564FDB',
			confirmButtonText: 'Close',
			title: `Schedule - ${company.title} ${title}`,
			html: true,
			text: appointmentElement,
			onConfirm: () => {
				globalHideSweetAlert()
			}
		})

	}

	statusFormatter(cell: Object, row: Object): Object {
		const {
			user
		} = this.props

		// if(row.appointment_id==null || !userRoles.includes(this.props.userRole.get('name'))){
		// 	disableChangeStatus = false
		// }
		let statusLabels = {
			null:'Need Review',
			0:'Need Review',
			1:'Published',
			2:'Rejected',
			3:'Canceled'
		}

		let colorChannel='tag-blue'
		if(row.status == 0 || row.status==null){
			colorChannel='tag-yellow'
		}else if(row.status == 1){
			colorChannel='tag-green'
		} else if(row.status == 2){
			colorChannel='tag-pink'
		} else if(row.status == 3){
			colorChannel='tag-pink'
		} else {
			colorChannel='tag-green'
		}

		return 	<div className={`tag-channel pull-left ${colorChannel}`}>
				<span>{statusLabels[row.status]}</span>
			</div>
	}

	handleUpdateStatusChange(e, row){
		const{
			handleUpdateStatus,
			publishJobsFilterData,
			publishJobsFetchData
		} = this.props
		handleUpdateStatus(row.id, e.target.value, row.appointment_id, row.channel).then(()=>publishJobsFetchData(publishJobsFilterData))
	}

	handlePreviewJob(e, company){
		e.preventDefault()
		this.setState({selectedJob: company})
		console.log('aaaaaaaa', this.state.selectedJob);
		$('#modalPreviewJob').modal('show')
	}
	handleStatusUpdate(){
		const { publishJobsFetchData } = this.props
		publishJobsFetchData(this.props.publishJobsFilterData)
	}

  	render() {
		const {
			publishJobs,
			publishJobsPagination,
			publishJobsAddFilterPageNumber,
			publishJobsFilterData,
			publishJobsFetchData,
			globalIndicatorData,
			user,
			pathName
		} = this.props
		
		let reportPagination: ?Object

		if (!publishJobs.isEmpty()) {
			reportPagination =
				<PaginationSupport
					pagination={publishJobsPagination}
					fetchData={publishJobsFetchData}
					filterData={publishJobsFilterData}
					addPageNumber={publishJobsAddFilterPageNumber} />
		}

		const isIndicatorClass = globalIndicatorData.get('isShow')? 'p-t-56' : ''
		const options = {
			onSortChange: this.handleSortChange,
			onRowClick: (row, cell)=>this.handleSelectRow(row, cell),
			noDataText: <TableLoader isFetched={this.props.isFetched} feature='custom-plan' />
		};

		let channel = 'Popskul'

    	return (

			<div className={isIndicatorClass}>
	      		<div className="div-support-container">
	        		<section className="wrapper">
	          			<div className="main-content">
	            			<div className="container-fluid">
	              				<div className="big-panel">
									<div className="flex-container">
										<h5 className="title-heading">{channel} Requiring Actions</h5>
									</div>

									<div className="flex-container flex-between">
										<div>
											Show
											<select
												className="select-status-user"
												name="display"
												id="display"
												defaultValue={publishJobsFilterData.get('per_page')}
												onChange={(e)=>this.handleChangePageSize(e)}>
												<option value="10" >10</option>
												<option value="20" >20</option>
												<option value="30" >30</option>
										  	</select>
										 	 Entries
											<select
												className="select-status-user"
												name="status"
												id="status"
												placeholder='Filter by Status'
												defaultValue={publishJobsFilterData.get('status')}
												value={publishJobsFilterData.get('status')}
												onChange={(e)=>this.handleFilterByStatus(e)}>
													<option value="" disabled selected>Filter by status</option>
													<option value="" >All</option>
													{
														statusList.map((status, index)=>
															<option key={index} value={status.value} selected={publishJobsFilterData.get('status') === status.value ? true:false}>{status.label}</option>
														)
													}
												
											</select>
											
										</div>
										<div>
											<div className="search-support-container horizontal-item--between">
												<input className="search-support" placeholder="Search by name or account holder" defaultValue={this.props.publishJobsFilterData.get('search')} onKeyPress={this.handleSearchByName}/>
												<i className="fa fa-search"></i>
											</div>
										</div>
									</div>

	                				<div className="report-list-container">
										<BootstrapTable
											data={publishJobs.toJS()}
											striped={true}
											options={options}
											hover
										>
											<TableHeaderColumn width='70' dataField="id" isKey={true} dataSort={true}>ID</TableHeaderColumn>
											<TableHeaderColumn dataField="company_name" dataSort={true}>Company Name</TableHeaderColumn>
											<TableHeaderColumn dataField="title"   dataSort={true}>Job/Program Title</TableHeaderColumn>
											<TableHeaderColumn dataField="due_date" dataSort={true} >Due Date</TableHeaderColumn>
											<TableHeaderColumn dataField="status" dataSort={true} dataFormat={this.statusFormatter} dataAlign='left'>Status</TableHeaderColumn>
											<TableHeaderColumn  dataSort={false} dataFormat={this.actionFormatter} dataAlign='center'>Action</TableHeaderColumn>
										</BootstrapTable>
										{reportPagination}
	                				</div>
	              				</div>
	            			</div>
	          			</div>
	        		</section>
	      		</div>
				<ModalPreviewJob job={this.state.selectedJob} onUpdated={this.handleStatusUpdate}/>
			</div>
    	)
  	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		publishJobs: state.publishJobs,
		publishJobsPagination: state.publishJobsPagination,
		publishJobsFilterData: state.publishJobsFilterData,
		publishJobsIsLoading: state.publishJobsIsLoading,
		globalIndicatorData: state.globalIndicatorData,
		isFetched: state.isFetched,
		userPrivilege: state.userPrivilege,
		accountManagers: state.accountManagers,
		user: state.userAuth,
		userRole: state.userAuth.get('role') || fromJS({}),
		pathName: ownProps.location.pathname
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		publishJobsFetchData: (filter_data) => dispatch(publishJobsFetchData(filter_data)),
		publishJobsAddFilterPageNumber: (page) => dispatch(publishJobsAddFilterPageNumber(page)),
		publishJobsAddFilterSearch: (search) => dispatch(publishJobsAddFilterSearch(search)),
		publishJobsAddFilterSort: (sort) => dispatch(publishJobsAddFilterSort(sort)),
		publishJobsAddFilterName: (name) => dispatch(publishJobsAddFilterName(name)),
		publishJobsAddFilterColPerPage: (col) => dispatch(publishJobsAddFilterColPerPage(col)),
		publishJobsAddFilterStatus: (status) => dispatch(publishJobsAddFilterStatus(status)),
		publishJobsClearFilterData: (status) => dispatch(publishJobsClearFilterData(status)),
		globalAddSweetAlertData: (options) => dispatch(globalAddSweetAlertData(options)),
		globalHideSweetAlert: () => dispatch(globalHideSweetAlert()),
		companiesReactivate: (filter_data) => dispatch(companiesReactivate(filter_data)),
		companiesUpgradeLevel: (companyId, levelId, prevPage) => dispatch(companiesUpgradeLevel(companyId, levelId, prevPage)),
		companiesRejectPlanRequest: (companyId, isReject) => dispatch(companiesRejectPlanRequest(companyId, isReject)),
		companiesUpdateAccountManager: (companyId, accountManagerId, appointmentId, channel) => dispatch(companiesUpdateAccountManager(companyId, accountManagerId, appointmentId, channel)),
		handleUpdateStatus: (companyId, status, appointmentId, channel) => dispatch(handleUpdateStatus(companyId, status, appointmentId, channel)),
		publishJobsAddFilterChannel: (channel) => dispatch(publishJobsAddFilterChannel(channel)),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(PublishJob)
