import configurations from '../../misc/configurations'

const validate = values => {
	const errors = {}

	if (!values.title) {
		errors.title = `Company Name can't be blank`
	}else{
		if(values.title.length < 3){
			errors.title = `Company Name cannot less than 3 characters`
		}
	}

	if (!values.profileDescription) {
		errors.profileDescription = `About Company can't be blank`
	} else{
		if(values.profileDescription.length < 10){
			errors.profileDescription = `About Company cannot less than 10 characters`
		}
	}

	if (!values.website) {
		errors.website = `Website can't be blank`
	} else{
		if (!configurations.urlRegex.test(values.website)) {
			errors.website = `Invalid url format`
		}
	}

	if (!values.country_id) {
		errors.country_id = `Please select Country`
	} 

	if(values.channel!='career_development_center'){
		if (!values.industry) {
			errors.industry = `Please select  Industry`
		}else{
			if(values.industry=='Other'){
				if (!values.other_industry) {
					errors.other_industry = `Industry can't be blank`
				}
			}
		}

		if (!values.company_size) {
			errors.company_size = `Company Size can't be blank`
		} 
	}
	 

	if (!values.timezone_id) {
		errors.timezone_id = `Please select timezone`
	} 

	if(values.popskul_connection==1 || values.channel=='career_development_center' ){
		if(!values.city){
			errors.city = `Please select location`
		}

	}

	if (!values.segment_type) {
		errors.segment_type = `Please select Segment Type`
	} 

	return errors
}

export default validate

