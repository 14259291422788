// @flow

import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import bindAll from 'lodash/bindAll'
import moment from 'moment'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import $ from 'jquery'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import axios from "../../dependencies/_axios"
import PaginationSupport from '../partials/PaginationSupport'
import defaultLogo from '../../assets/img/default-company-image.png'

import ModalDownloadAccounts from '../partials/ModalDownloadAccounts'
import ModalOtherBillingAgreement from '../partials/ModalOtherBillingAgreement'
import ModalUpdateMetricData from '../partials/ModalUpdateMetricData'
import ModalLeavePage from '../partials/ModalLeavePage'

import {
	globalAddSweetAlertData,
	globalHideSweetAlert,
} from '../../actions/global'

import {
	companiesGetDetail,
	companiesGetCredit,
	companiesSuspend,
	companiesReactivate,
	companiesUpgradeLevel,
	creditsStatementAddFilterCompanyId,
	creditsStatementAddFilterPerPage,
	creditsStatementAddFilterPageNumber,
	creditsStatementAddFilterSort,
	creditsStatementAddFilterSearch,
	creditsStatementAddFilterFrom,
	creditsStatementAddFilterTo,
	creditsStatementClearFilterData,
	companiesUpdateAccountManager,
	updateMetric,
	updateCompanyAdditionalPlan
} from '../../actions/companies'

import TableLoader from '../partials/TableLoader'
import ModalConnectCDC from '../partials/ModalConnectCDC'

type Props = {
	globalAddSweetAlertData: Function,
	globalHideSweetAlert: Function,
	companiesGetDetail: Function,
	companiesGetCredit: Function,
	companiesSuspend: Function,
	companiesReactivate: Function,
	companiesUpgradeLevel: Function,
	companiesId: string,
	creditsStatementAddFilterCompanyId: Function,
	creditsStatementAddFilterPerPage: Function,
	creditsStatementAddFilterPageNumber: Function,
	creditsStatementAddFilterSort: Function,
	creditsStatementAddFilterSearch: Function,
	creditsStatementAddFilterFrom: Function,
	creditsStatementAddFilterTo: Function,
	creditsStatementClearFilterData: Function,
};

let _changeAdditionalPlan;
class CompanyDetail extends React.Component<Props, State> {

	constructor(props) {
		super(props)

		this.state = {
            isDownloading: false,
			from: null,
			to: null,
			minDate: moment(),
			maxDate: moment(),
			accountManagerId: null,
			metric: { startDate:'', endDate:'', credit:'', contract:'', billing:'', billing_other:'' },
			flag_controlled:'',
			flag_pilot:'',
		}

		bindAll(this, [
			'handleSuspendCompany',
			'handleUpgradeCompany',
			'handleReactiveCompany',
			'handleDownloadCreditStatement',
			'handleFilterFromDate',
			'handleFilterToDate',
			'handleSubmitFilter',
			'handleSortChange',
			'handleModalDownload',
			'handleSaveMetrics',
			'creditProgramFormater',
			'changeStartDate',
			'changeEndDate',
			'changeCredit',
			'changeContract',
			'changeBilling',
			'billingOther',
			'handleConnectDisconnectCDC',
			'onCloseModal',
			'changeAdditionalPlan',
		])
	}

	componentDidMount() {
		const {
			companiesGetDetail,
			companiesGetCredit,
            companiesId,
			creditsStatementAddFilterCompanyId,
			creditsStatementClearFilterData,
		} = this.props
		companiesGetDetail(companiesId).then(() => {
			const company = this.props.company
			const metric = company.get('metric')
			if(metric) {
				this.setState({
					metric: {
						startDate: (metric.start_date ? moment(metric.start_date) : ""),
						endDate: (metric.end_date ? moment(metric.end_date) : ""),
						credit: metric.credit,
						contract: metric.contract,
						billing: metric.billing,
						billing_other: metric.billing_other
					}
				})
			}
			this.setState({
				flag_controlled: company.get('additional_plan') ? company.get('additional_plan').controlled:  '',
				flag_pilot: company.get('additional_plan') ? company.get('additional_plan').pilot : ''
			})
		})
		// creditsStatementClearFilterData().then(()=>{
		// 	creditsStatementAddFilterCompanyId(companiesId).then(() => companiesGetCredit(this.props.creditsStatementFilterData))
		// })
		
	}

	handleModalDownload(e: Object){
		e.preventDefault()

		$('#modalDownloadAccounts').modal('show')
	}

	handleSaveMetrics(e: Object){
		e.preventDefault()

		$('#modalUpdateMetricData').modal('show')
	}

	handleUpgradeCompany(e, levelId, levelTitle){
		e.preventDefault()
		const {
			globalAddSweetAlertData,
			globalHideSweetAlert,
			companiesUpgradeLevel,
			companiesId,
			companiesGetDetail
		} = this.props

		globalAddSweetAlertData({
			show: true,
			showCancelButton: true,
			customClass: 'swal-support',
			confirmButtonColor: '#564FDB',
			confirmButtonText: 'Upgrade',
			cancelButtonText: 'Cancel',
			title: `Upgrade to ${levelTitle}?`,
			html: true,
			text: `This account level will be adjusted to “<strong>${levelTitle}</strong>” once you upgrade it. The remaining credits and active periods from the previous level will be added to your new level.`,
			onConfirm: () => {
				globalHideSweetAlert()
				companiesUpgradeLevel(companiesId, levelId)
				// .then(() => {
				// 	companiesGetDetail(companiesId)
				// 	companiesGetDetail(companiesId)
				// })
				
			},
			onCancel: () => {
				globalHideSweetAlert()
			}
		})
	}

	handleReactiveCompany(e){
		e.preventDefault()
		const {
			globalAddSweetAlertData,
			globalHideSweetAlert,
			companiesGetDetail,
			companiesReactivate,
			companiesId,
			company
		} = this.props

		globalAddSweetAlertData({
			show: true,
			showCancelButton: true,
			customClass: 'swal-support',
			confirmButtonColor: '#564FDB',
			confirmButtonText: 'Reactivate',
			cancelButtonText: 'Cancel',
			title: `Reactivate company’s account?`,
			text: "This account is currently suspended. You will halt the suspension process and reactivate this company’s account.",
			onConfirm: () => {
				companiesReactivate(companiesId)
				.then(()=>{
					companiesGetDetail(companiesId)
					globalHideSweetAlert()
				})
				
			},
			onCancel: () => {
				globalHideSweetAlert()
			}
		})
	}

	handleSuspendCompany(e){
		e.preventDefault()
		const {
			globalAddSweetAlertData,
			globalHideSweetAlert,
			companiesGetDetail,
			companiesSuspend,
			companiesId,
			company
		} = this.props

		let detailcompany = company.toJS();

		globalAddSweetAlertData({
			show: true,
			showCancelButton: true,
			customClass: 'swal-support',
			confirmButtonColor: '#EA4D4D',
			confirmButtonText: 'Suspend',
			cancelButtonText: 'Cancel',
			title: `Suspend company’s account?`,
			html: true,
			text: `Users of ${detailcompany.title} account will no longer be able to view their question library, candidates, and jobs.<br />They will be prompted to contact help@astrnt.co to remove the suspension.`,
			onConfirm: () => {
				companiesSuspend(companiesId)
				.then(()=>{
					companiesGetDetail(companiesId)
					globalHideSweetAlert()
				})
			},
			onCancel: () => {
				globalHideSweetAlert()
			}
		})
	}

	updateAccountManager(val) {
		const { companiesId, companiesUpdateAccountManager } = this.props
		companiesUpdateAccountManager(companiesId, val, 0)
		this.setState({accountManagerId: val})
	}

	handleDownloadCreditStatement(e){
		e.preventDefault()
		const {
			creditsStatementFilterData,
            companiesId
		} = this.props
		this.setState({isDownloading:true})
		axios({
			url: `/support-dashboard/companies/${companiesId}/credits-account-statement/export`,
			method: 'POST',
			responseType: 'blob',
			crossDomain: true,
			data: creditsStatementFilterData
		}).then((response) => {
			setTimeout(()=>{
				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement('a')
				let fileName = 'Credit Statement'
				if(creditsStatementFilterData.get('from')!=''){
					fileName = `${fileName} ${creditsStatementFilterData.get('from')}`
				}

				if(creditsStatementFilterData.get('to')!=''){
					fileName = `${fileName} to ${creditsStatementFilterData.get('to')}`
				}

				fileName = `${fileName}.csv`
				link.href = url
				link.setAttribute('download', `${fileName}`)
				document.body.appendChild(link);
				link.click()
				this.setState({isDownloading:false})
			}, 3000)
		})
		.catch((error)=>{
			this.setState({isDownloading:false})
		})
	}

	handleFilterFromDate(dateVal){
		const { creditsStatementAddFilterFrom } = this.props
		this.setState({from:moment(dateVal),minDate:dateVal, to:''})
		let maxDate = moment(dateVal).add(1,"year")
		if(maxDate<moment()) {this.setState({maxDate:maxDate})}
		else this.setState({maxDate:moment()})
		creditsStatementAddFilterFrom(dateVal.format('DD-MM-YYYY'))
	}

	handleFilterToDate(dateVal){
		const {
			creditsStatementAddFilterTo
		} = this.props
		this.setState({to:moment(dateVal)})
		creditsStatementAddFilterTo(dateVal.format('DD-MM-YYYY'))
	}

	handleSubmitFilter(e){
		e.preventDefault()
		this.props.companiesGetCredit(this.props.creditsStatementFilterData);
	}

	handleSortChange(name, order){
		const {
			companiesGetCredit,
			creditsStatementAddFilterSort
		} = this.props

		let sort={
			name: name,
			value: order
		}

		creditsStatementAddFilterSort(sort).then(()=>companiesGetCredit(this.props.creditsStatementFilterData))

	}

	creditProgramFormater(cell: Object, row: Object): Object{
		if(['Top Up Credits','Free Credits for Free Trial'].includes(row.title)){
			return '-'
		}
		return row.title
	}

	changeStartDate(val) {
		let metric = this.state.metric
		metric.startDate = val
		this.setState({metric: metric})
	}

	changeEndDate(val) {
		let metric = this.state.metric
		metric.endDate = val
		this.setState({metric: metric})
	}

	changeCredit(val) {
		let metric = this.state.metric
		metric.credit = val
		this.setState({metric: metric})
	}
	
	changeContract(val) {
		let metric = this.state.metric
		metric.contract = val
		this.setState({metric: metric})
	}

	changeBilling(val) {
		let metric = this.state.metric
		metric.billing = val
		if(val=="Other")
			$("#modalOtherBillingAgreement").modal("show")
		else 
			metric.billing_other = null
		this.setState({metric: metric})
	}

	billingOther(val) {
		let metric = this.state.metric
		metric.billing_other = val
		this.setState({metric: metric})
	}

	handleConnectDisconnectCDC(e, company){
		e.preventDefault()
		$('#ModalConnectCDC').modal('show')
	}

	onCloseModal(){
		this.props.companiesGetDetail(this.props.company.get('id'))
	}

	changeAdditionalPlan(e, type) { // [controlled,pilot]
		e.preventDefault()

		const { company, updateCompanyAdditionalPlan } = this.props
		const checked = e.target.checked

		clearTimeout(_changeAdditionalPlan);
		_changeAdditionalPlan = setTimeout(() => {
			updateCompanyAdditionalPlan(company.get('id'), type, checked)
			if(type == 'controlled') {
				this.setState({flag_controlled:checked})
			} else {
				this.setState({flag_pilot:checked})
			}
		}, 1000)
	}

  	render() {
		const {
			company,
			credit,
			creditsStatementFilterData,
			companiesGetCredit,
			creditsStatementAddFilterPageNumber,
			creditsStatementPagination
		} = this.props

		let detailcompany = company.toJS();
		let companyAdmin = detailcompany.administrator  || {}
		let companyCredit = detailcompany.credit || {}
		let companyLevel = detailcompany.levels || []
		companyLevel = companyLevel.filter(level=> ![detailcompany.company_level,9].includes(level.id))
		let userLevelLabel;
		let userLevelBadgeStatus;
		if ([1,9].includes(detailcompany.company_level)) {
	      userLevelLabel = detailcompany.level.level_name
	      userLevelBadgeStatus = (detailcompany.company_status == 'Suspended' ? 'suspended' : (detailcompany.company_status == 'Expired' ? 'suspended' : 'paid'))
	    } else if ([2,8].includes(detailcompany.company_level)) {
			userLevelLabel = detailcompany.level.level_name
	      userLevelBadgeStatus = (detailcompany.company_status == 'Suspended' ? 'suspended' : (detailcompany.company_status == 'Expired' ? 'suspended' : 'paid'))
	    } else if (detailcompany.company_level == 3) {
	      userLevelLabel = "Testing User"
	      userLevelBadgeStatus = (detailcompany.company_status == 'Suspended' ? 'suspended' : (detailcompany.company_status == 'Expired' ? 'suspended' : 'testing'))
	    } else if ([4,10,11,12,13].includes(detailcompany.company_level)) {
	      userLevelLabel = detailcompany.level.level_name
	      userLevelBadgeStatus = (detailcompany.company_status == 'Suspended' ? 'suspended' : (detailcompany.company_status == 'Expired' ? 'suspended' : 'trial'))
	    } else if (detailcompany.company_level == 5) {
	      userLevelLabel = detailcompany.level.level_name
	      userLevelBadgeStatus = (detailcompany.company_status == 'Suspended' ? 'suspended' : (detailcompany.company_status == 'Expired' ? 'suspended' : 'api'))
	    } else if (detailcompany.company_level == 6) {
	      userLevelLabel = detailcompany.level.level_name
	      userLevelBadgeStatus = (detailcompany.company_status == 'Suspended' ? 'suspended' : (detailcompany.company_status == 'Expired' ? 'suspended' : 'api'))
	    } else if (detailcompany.company_level == 7) {
	      userLevelLabel = detailcompany.level.level_name
	      userLevelBadgeStatus = (detailcompany.company_status == 'Suspended' ? 'suspended' : (detailcompany.company_status == 'Expired' ? 'suspended' : 'api'))
	    } else {
	      userLevelLabel = ""
	    }

	    let creditList = credit.toJS();

		let creditPagination: ?Object
		
		if (!credit.isEmpty()) {
			creditPagination =
				<PaginationSupport
					pagination={creditsStatementPagination}
					fetchData={companiesGetCredit}
					filterData={creditsStatementFilterData}
					addPageNumber={creditsStatementAddFilterPageNumber} />
		}

		const options = {
			onSortChange: this.handleSortChange,
			noDataText: <TableLoader isFetched={this.props.isFetched}/>
		}

		let prevState = this.props.location.state
		let backPath = '/support/university'
		let upgradeTo = ''
		if(prevState !== undefined){
			if(prevState.prevPage === 'company-index'){
				prevState = 'company-detail'
			}else if( prevState.prevPage === 'custom-plan'){
				upgradeTo = prevState.upgradeTo
				prevState = 'custom-plan'
				backPath='/support/custom-plan'	
			}
		}

		let disabled_apply = (this.state.to ? false : true)
		let disabled_to = (this.state.from ? false : true)
		let accountManagerId = (this.state.accountManagerId ? this.state.accountManagerId : detailcompany.account_manager_id)

		let buttonLabelConnect = 'Connect'
		let buttonClassConnect = 'button__primary'
		let colorLabelConnect  = 'tag-default'
		let labelConnect       = 'Not Connected to Career Development Center Dashboard'
		if(company.get('channel') == 'career_development_center'){
			labelConnect       = 'Connected to Career Development Center Dashboard'
			colorLabelConnect  = 'tag-cdc'
			buttonLabelConnect = 'Disconnect'
			buttonClassConnect = 'button__secondary'
		}

		return (
			<div className="div-support-container">
				<section className="wrapper">
				<div className="main-content">
					<div className="container-fluid">
					<div className="big-panel">
						<div className="flex-container">
							<Link to={{pathname : backPath, state:{prevPage:prevState}}} className="btn-support"><i className="fa fa-angle-left" style={{fontSize: '18px'}}></i> University Lists</Link>
							{ 
								prevState === 'custom-plan' &&
								<div className='badge-plan-request'><i className="fa fa-info-circle"></i> {upgradeTo} Plan Request</div>
							}
						</div>
						<div className="flex-container horizontal-item--between m-t-40">
							<h5 className="title-heading m-b-0">{detailcompany.title}</h5>
							<div>
								{ detailcompany.suspended==0 &&
									<li className="dropdown inline-content">
										<button data-toggle="dropdown" className="dropdown-toggle btn-support-action primary m-r-12">Upgrade to Change Account Type<i className="fa fa-caret-down"></i></button>
										<ul className="dropdown-menu dropdown-upgrade">
											{
												companyLevel.map((level, index)=>{
													return (
														<li key={index}><a onClick={(e)=>this.handleUpgradeCompany(e, level.id, level.name)}>{level.name}</a></li>
													)
												})
											}
										</ul>
									</li>
								}
								{ detailcompany.suspended==1 &&
									<button className={`btn-support-action primary m-r-12 ${detailcompany.suspended ? '' : 'hide'}`} onClick={(e)=>this.handleReactiveCompany(e)}>Reactivate</button>
								}
								{ detailcompany.company_status !='Expired' &&
									<button onClick={(e)=>this.handleSuspendCompany(e)} className={`btn-support-action danger m-r-12 ${detailcompany.suspended ? 'hide' : ''}`}>Suspend</button>
								}
								<Link to={`/support/university/${detailcompany.id}/edit`} className="btn-support-action secondary">Edit Organization</Link>
							</div>
						</div>
						<div className="flex-container flex-between m-t-40">
							<div className="col-md-8">
								<div className="company-detail-card detail-card">
									<div className="flex-container horizontal-item--between">
										<label>Detail</label>
										<div className='flex'>
											<div className={`badge-company university`}>
												University
											</div>
										</div>
									</div>
									<hr className="hr-detail" />
									<div className="logo-desc-container vertical-item--top">
										<div className="logo-container">
											<div className="horizontal-item--center logo-div"><img src={detailcompany.logoUrl!='' ? detailcompany.logoUrl: defaultLogo} /></div>
										</div>
										<div className="desc-container flex-grow">
											<div className='row m-b-15'>
												<div className={`tag-channel pull-left m-r-10 ${colorLabelConnect}`}>
													{labelConnect}
												</div>
												<div className='m-t-4'>
													<button onClick={(e)=>this.handleConnectDisconnectCDC(e, company)} className={`button ${buttonClassConnect}`}>  {buttonLabelConnect}  </button>
												</div>
											</div>
											
											{/* <label>{detailcompany.title}</label> */}
											{/* <span>{detailcompany.website}</span> */}
											<p className="p-desc">{detailcompany.profileDescription}</p>
										</div>
									</div>
								</div>
							</div>
							<div className="col-md-4">
								<div>
									<button onClick={this.handleModalDownload} className="btn-support-action primary w-100 m-b-8 p-l-16 p-r-16 p-t-12 p-b-12"><i className='icon-i-download-white'></i>Download Account Insight</button>
								</div>
								<div className="company-detail-card admin-card">
									<div><label>Administrator</label></div>
									<div><span>{companyAdmin.name}</span></div>
									<div className="m-t-5"><p>{companyAdmin.email}</p></div>
								</div>
								<div className="company-detail-card account-manager-card m-t-8">
									<div><label>Account Manager</label></div>
									<div>
										<select
											className="select-account-manager"
											name="manager"
											id="manager"
											onChange={(e) => this.updateAccountManager(e.target.value)}
											value={accountManagerId}>
											<option value="" disabled>Select</option>
											{/* <option value="Nigel Hembrow" >Nigel Hembrow</option>
											<option value="Chandra Marsono" >Chandra Marsono</option>
											<option value="Devi Miraj" >Devi Miraj</option> */}
											{ this.props.accountManagers.map((d,i) => (<option key={i} value={d.get("id")}>{d.get("name")}</option>)) }
									  	</select>
									</div>
								</div>
							</div>
						</div>

						<div className="flex-container m-t-16">
							<div className="col-md-12">
								<div className="company-detail-card p-24">
									<label>Commercial Metrics</label>
									<div className="flex-container flex-between metrics-container">
										<div className="vertical-item--between">
											<label>Start Date of the Agreement</label>
											<div className="datepick-container-new horizontal-item--between">
												<DatePicker
													onChange = {this.changeStartDate}
													value={this.state.metric.startDate ? this.state.metric.startDate.format("DD/MM/YYYY") : ''}
													dateFormat="DD/MM/YYYY"
													placeholderText="DD/MM/YYYY"
													/>
												<i className="icon-i-calendar" />
											</div>
										</div>
										<div className="vertical-item--between">
											<label>End date of the Agreement</label>
											<div className="datepick-container-new horizontal-item--between">
												<DatePicker
													onChange= {this.changeEndDate}
													value={this.state.metric.endDate ? this.state.metric.endDate.format("DD/MM/YYYY") : ''}
													dateFormat="DD/MM/YYYY"
													placeholderText="DD/MM/YYYY"
													/>
												<i className="icon-i-calendar" />
											</div>
										</div>
										{/* <div className="vertical-item--between">
											<label>Total number of credits purchased</label>
											<input className="input-credits-value" type="number" maxLength='7' placeholder="1.000" value={this.state.metric.credit} onChange={(e) => this.changeCredit(e.target.value)}></input>
										</div> */}
										<div className="vertical-item--between">
											<label>Contract Value in Normalised SGD</label>
											<input className="input-credits-value" type="number" maxLength='7' placeholder="1.000" value={this.state.metric.contract} onChange={(e) => this.changeContract(e.target.value)}></input>
										</div>
										<div className="vertical-item--between">
											<label>Billing agreement</label>
											<div>
												<select
													className="select-account-manager"
													name="agreement"
													id="agreement"
													placeholder="Select"
													value={this.state.metric.billing}
													onChange={(e) => this.changeBilling(e.target.value)}>
													<option value="" disabled>Select</option>
													<option value="Annually">Annually</option>
													<option value="Quarterly">Quarterly</option>
													<option value="Monthly">Monthly</option>
													<option value="Other">Other{this.state.metric.billing=="Other" && this.state.metric.billing_other ? `: ${this.state.metric.billing_other}`:""}</option>
											  	</select>
											</div>
										</div>
									</div>
									<div className="flex-container flex-end">
										<button onClick={this.handleSaveMetrics} disabled={false} className="btn-support-action primary m-t-16 m-r-12 p-l-24 p-r-24 p-t-12 p-b-12">Save</button>
									</div>
								</div>
							</div>
						</div>

						{/* <div className="table-credit-container">
							<label>Credits Account Statement</label>
							<div className="div-download-csv flex-container flex-between">
								<div className="div-date-range flex-container vertical-item--center">
									<label className="m-r-16">From</label>
									<div className="datepick-container-new horizontal-item--between">
										<DatePicker
											onChange = {this.handleFilterFromDate}
											value={this.state.from ? this.state.from.format("DD/MM/YYYY") : ''}
											dateFormat="DD/MM/YYYY"
											placeholderText="DD/MM/YYYY" 
											maxDate={moment()}
											// selected={this.state.from}
											/>
										<i className="icon-i-calendar" />
									</div>
									<label className="m-l-important-16 m-r-16">To</label>
									<div className="datepick-container-new horizontal-item--between">
										<DatePicker
											onChange= {this.handleFilterToDate}
											value={this.state.to ? this.state.to.format("DD/MM/YYYY") : ''}
											dateFormat="DD/MM/YYYY"
											placeholderText="DD/MM/YYYY" 
											minDate={this.state.minDate}
											maxDate={this.state.maxDate} 
											// selected={this.state.to}
											disabled={disabled_to}
											/>
										<i className="icon-i-calendar" />
									</div>
									<button onClick={this.handleSubmitFilter} className="btn-support-action primary m-l-12 p-l-16 p-r-16 p-t-12 p-b-12" disabled={disabled_apply}>Apply</button>
								</div>
								<div className="div-button flex-container vertical-item--center">
									<button disabled={this.state.isDownloading} className="btn-support-action transparent" onClick={this.handleDownloadCreditStatement}><i className={`${this.state.isDownloading ? 'hide':'icon-i-download-purple'}`}></i><i className={`${this.state.isDownloading ? 'fa fa-spinner fa-pulse fa-fw m-r-5':'hide'}`}></i>Download CSV</button>
								</div>
							</div>
							<div className="report-list-container form-custom">
								<BootstrapTable
									data={creditList.data}
									striped={true}
									options = {options}
								>
									<TableHeaderColumn dataField="id" isKey={true} dataSort={false} hidden>ID</TableHeaderColumn>
									<TableHeaderColumn dataField="date" dataSort={true}>Date (GMT+7)</TableHeaderColumn>
									<TableHeaderColumn dataField="description" dataSort={false}>Description</TableHeaderColumn>
									<TableHeaderColumn dataField="title" dataSort={false} dataFormat={this.creditProgramFormater}>Job/Program</TableHeaderColumn>
									<TableHeaderColumn dataField="credit" dataSort={false}>Top Up / Usage</TableHeaderColumn>
									<TableHeaderColumn dataField="balance" dataSort={false}>Balance</TableHeaderColumn>
								</BootstrapTable>
								{creditPagination}
							</div>
						</div> */}
					</div>
					</div>
				</div>
				</section>
				<ModalConnectCDC company={this.props.company.toJS()} onCloseModal={this.onCloseModal}/>
				<ModalDownloadAccounts />
				<ModalOtherBillingAgreement callback={this.billingOther} val={this.state.metric.billing_other} />
				<ModalUpdateMetricData companyId={detailcompany.id} data={this.state.metric} callback={this.props.updateMetric} />
				<ModalLeavePage />
			</div>
		)
  	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		companiesId: ownProps.match.params.companiesId,
		company: state.company,
		credit: state.credit,
		creditsStatementFilterData: state.creditsStatementFilterData,
		creditsStatementPagination: state.creditsStatementPagination,
		isFetched: state.isFetched,
		accountManagers: state.accountManagers,
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		globalAddSweetAlertData: (options) => dispatch(globalAddSweetAlertData(options)),
		globalHideSweetAlert: () => dispatch(globalHideSweetAlert()),
		companiesGetDetail: (filter_data) => dispatch(companiesGetDetail(filter_data)),
		companiesGetCredit: (companyId, filter_data) => dispatch(companiesGetCredit(companyId, filter_data)),
		companiesSuspend: (filter_data) => dispatch(companiesSuspend(filter_data)),
		companiesReactivate: (filter_data) => dispatch(companiesReactivate(filter_data)),
		companiesUpgradeLevel: (companyId, levelId) => dispatch(companiesUpgradeLevel(companyId, levelId)),
		creditsStatementAddFilterCompanyId: company_id => dispatch(creditsStatementAddFilterCompanyId(company_id)),
		creditsStatementAddFilterPerPage: per_page => dispatch(creditsStatementAddFilterPerPage(per_page)),
		creditsStatementAddFilterPageNumber: page => dispatch(creditsStatementAddFilterPageNumber(page)),
		creditsStatementAddFilterSort: sort => dispatch(creditsStatementAddFilterSort(sort)),
		creditsStatementAddFilterSearch: search => dispatch(creditsStatementAddFilterSearch(search)),
		creditsStatementAddFilterFrom: from => dispatch(creditsStatementAddFilterFrom(from)),
		creditsStatementAddFilterTo: to => dispatch(creditsStatementAddFilterTo(to)),
		creditsStatementClearFilterData : () => dispatch(creditsStatementClearFilterData()),
		companiesUpdateAccountManager: (companyId, accountManagerId, appointmentId) => dispatch(companiesUpdateAccountManager(companyId, accountManagerId, appointmentId)),
		updateMetric: (companyId, data) => dispatch(updateMetric(companyId, data)),
		updateCompanyAdditionalPlan: (companyId, type, additional_plan) => dispatch(updateCompanyAdditionalPlan(companyId, type, additional_plan)),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyDetail)
