// @flow

import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import range from 'lodash/range'
import bindAll from 'lodash/bindAll'
import { Map } from 'immutable'

type Props = {
  fetchData: Function,
  addPageNumber: Function,
  addPerPageSize:Function,
  filterData: Map<string, any>,
  pagination: Map<string, any>,
  companyId: string
};

class PaginationSupport extends React.Component<Props> {
	constructor(props) {
		super(props);

		bindAll(this, [
			'pageRange',
			'handlePrevLinkClick',
			'handleNextLinkClick',
			'handleLinkClick',
			'handleLastClick',
			'handleFirstLinkClick'
		])
	}

	pageRange(): Array<number> {
		const { pagination } = this.props

		let startPage: number = 0
		let endPage: number = 0
		let pages: Array<number> = []

		const totalPages = Math.ceil(pagination.get('total') / pagination.get('per_page'))

		if (totalPages <= 5) {
			startPage = 1
			endPage = totalPages
		} else {

			if (pagination.get('current_page') <= 3) {
				startPage = 1
				endPage = 5
			} else if (pagination.get('current_page') + 2 >= totalPages) {
				startPage = totalPages - 4
				endPage = totalPages
			} else {
				startPage = pagination.get('current_page') - 2
				endPage = pagination.get('current_page') + 2
			}

		}

		pages = range(startPage, endPage + 1)

		return pages
	}

	handlePrevLinkClick(): boolean | void {
		const {
			addPageNumber,
			fetchData,
			companyId,
			pagination,
			viewType,
			jobId,
		} = this.props

		const pageNumber: number = pagination.get('current_page') - 1
		const totalPages: number = pagination.get('total') / pagination.get('per_page')

		if (pageNumber < 1 || pageNumber > totalPages){
			return false
		}

		addPageNumber(pageNumber).then(() => {
			fetchData(this.props.filterData)
		})
	}

	handleFirstLinkClick(e){

		const {
			addPageNumber,
			fetchData,
			companyId,
			pagination,
			viewType,
			jobId,
		} = this.props

		const pageNumber: number = 1
		const totalPages: number = pagination.get('total') / pagination.get('per_page')

		if (pageNumber < 1 || pageNumber > totalPages){
			return false
		}

		addPageNumber(pageNumber).then(() => {
			fetchData(this.props.filterData)
		})

	}

	handleNextLinkClick(): boolean | void {
		const {
			addPageNumber,
			fetchData,
			companyId,
			pagination,
			viewType,
			jobId,
		} = this.props

		const pageNumber: number = pagination.get('current_page') + 1
		const totalPages: number = pagination.get('total') / pagination.get('per_page')

		if (pageNumber < 1 || pagination.get('current_page') > totalPages) {
			return false
		}

		addPageNumber(pageNumber).then(() => {
			fetchData(this.props.filterData)
		})
	}

	handleLastClick(e){
		const {
			addPageNumber,
			fetchData,
			companyId,
			pagination,
			viewType,
			jobId,
		} = this.props

		const pageNumber: number = pagination.get('last_page')


		if (pageNumber < 1 || pagination.get('current_page') > pagination.get('last_page')) {
			return false
		}

		addPageNumber(pageNumber).then(() => {
			fetchData(this.props.filterData)
		})
	}

	handleLinkClick(e) {
		const {
			addPageNumber,
			fetchData,
			addPerPageSize,
			perPageSize,
		} = this.props
		
		const pageNumber: number = e.target.dataset.page
		addPageNumber(pageNumber).then(() => {
			if (perPageSize==0 || perPageSize==''|| perPageSize== undefined) {
				fetchData(this.props.filterData)
			}else{
				addPerPageSize(perPageSize).then(()=>{
						fetchData(this.props.filterData)
				})
			}
		})
	}

	render() {
		const { pagination } = this.props

		const pages: Array<Object> = []

		const totalPages = pagination.get('total') / pagination.get('per_page')


		if (totalPages > 1) {
			if (pagination.get('current_page') == 1) {

			pages.push(
				<button
					type="button"
					key="page-prev"
					className="btn btn-white btn-big disabled">
					Previous
				</button>
			)


			}else{

			pages.push(
				<button
					type="button"
					key="page-prev"
					className="btn btn-white btn-big"
					onClick={this.handlePrevLinkClick}>
					Previous
				</button>
			)


			}


			const pageRange: Array<number> = this.pageRange()

			if (pagination.get('current_page') >= 4 && pagination.get('last_page') > 5) {
				pages.push(
					<button
						className="btn btn-white btn-number"
						data-page={1}
						key={"page-link-" + 1}
						onClick={this.handleLinkClick}>
						{1}
					</button>
				)

				pages.push(
					<button
						type="button"
						key="page-label"
						className="btn btn-white disabled">
						<i className="fa fa-ellipsis-h"></i>
					</button>
				)
				

			}

			for (var i = 0; i < pageRange.length; i++) {
				const pageNumber: number = pageRange[i]

				if (pageNumber === pagination.get('current_page')) {
					pages.push(
						<button className="btn btn-white btn-number active" key={'page-link-' + pageNumber}>{pageNumber}</button>
					)
				} else {
					pages.push(
						<button
							className="btn btn-white btn-number"
							data-page={pageNumber}
							key={"page-link-" + pageNumber}
							onClick={this.handleLinkClick}>
							{pageNumber}
						</button>
					)
				}
			}


			if (pagination.get('current_page') <= pagination.get('last_page')-3 && pagination.get('last_page') > 5 ) {

					pages.push(
						<button
							type="button"
							key="page-label-last"
							className="btn btn-white disabled">
							<i className="fa fa-ellipsis-h"></i>
						</button>
					)

					pages.push(
						<button
							className="btn btn-white btn-number"
							data-page={pagination.get('last_page')}
							key={"page-link-" + pagination.get('last_page')}
							onClick={this.handleLinkClick}>
							{pagination.get('last_page')}
						</button>
					)

			}
				if (pagination.get('current_page')===pagination.get('last_page')) {

					pages.push(
						<button
							type="button"
							key="page-next"
							className="btn btn-white btn-big disabled">
							Next
						</button>
					)
				}else{

					pages.push(
						<button
							type="button"
							key="page-next"
							className="btn btn-white btn-big"
							onClick={this.handleNextLinkClick}>
							Next
						</button>
					)

				}


		} else {
			pages.push(
				<button className="btn btn-white active" key='page-link-1'>1</button>
			)
		}

		return(
			<div className="paginations-wrapper-support horizontal-item--between">
			  <div className="progress-page">
			    Showing {pagination.get('from')} to {pagination.get('to')} of {this.props.pagination.get('total')} entries
			  </div>
			  <div className="btn-group">
					{pages}
			  </div>
			</div>
		)
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		companyId: ownProps.match.params.companyId
	}
}

export default withRouter(connect(mapStateToProps, null)(PaginationSupport))
